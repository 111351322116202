import { ApolloError, gql, useMutation } from "@apollo/client";
import { Grid } from "@mui/material";

import UploadUserCard from "../../../components/Base/AdminLayout/UploadUser/UploadUserCard";
import UploadUserTable from "../../../components/Base/AdminLayout/UploadUser/UploadUserTable";
import Title from "../../../components/Title";

const MUTATION = gql`
  mutation ($file: Upload!) {
    ADUploadAccount(file: $file) {
      accounts {
        account {
          username
          firstName
          lastName
          birthDate
          internalId
          personIdentity
          identityType
          microsoftMail
          googleMail
          joinedEducationYear
          joinedType
          desiredPrefix
          __typename
        }
        importType
        __typename
      }
    }
  }
`;

const UploadUser = () => {
  const [uploadFile, { loading, data, error }] = useMutation(MUTATION);

  return (
    <>
      <Title title="อัปโหลดผู้ใช้" />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={5}>
          <UploadUserCard
            uploadFile={uploadFile}
            data={data?.ADUploadAccount.accounts}
            loading={loading}
            error={error as ApolloError}
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <UploadUserTable
            data={data?.ADUploadAccount.accounts}
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UploadUser;
