import { Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
} from "@mui/material";

import editMail from "../../../../../graphql/mutation/ADEditMail.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import { ChangeMailValue } from "../../../../../types/interfaces";
import {
  getEmailExample,
  getEmailNameTerms,
} from "../../../../../utils/getEmailTerms";
import { ChangeMailValid } from "../../../../../validations/AdminValidations/AdminValidations";
import { FormAlert } from "../../../../Alert/FormAlert";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";
import CustomTextField from "../../../../TextFields/CustomTextField";

interface ChangeMailDialogProps {
  id: string;
  refetch: () => void;
  isStudentIdPrefixNeeded: boolean;
  internalId: string;
}

const ChangeMailDialog = ({
  id,
  refetch,
  isStudentIdPrefixNeeded,
  internalId,
}: ChangeMailDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>แก้ไขอีเมล</Button>
      <CustomDialog
        title="แก้ไขชื่ออีเมล"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        <ChangeMailForm
          id={id}
          refetch={refetch}
          setOpen={setOpen}
          isStudentIdPrefixNeeded={isStudentIdPrefixNeeded}
          internalId={internalId}
        />
      </CustomDialog>
    </>
  );
};

interface ChangeMailFormProps {
  id: string;
  refetch: () => void;
  setOpen: (arg0: boolean) => void;
  isStudentIdPrefixNeeded: boolean;
  internalId: string;
}

const ChangeMailForm = ({
  id,
  refetch,
  setOpen,
  isStudentIdPrefixNeeded,
  internalId,
}: ChangeMailFormProps) => {
  const [changeMail, { loading }] = useMutation(editMail);
  const initialValues: ChangeMailValue = { alert: "", desiredPrefix: "" };
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeMailValid}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(false);
        try {
          await changeMail({
            variables: {
              accountId: id,
              desiredPrefix: `${
                isStudentIdPrefixNeeded ? (internalId || "00000") + "." : ""
              }${values.desiredPrefix}`,
            },
          });
          await refetch();
          enqueueSnackbar("เปลี่ยนชื่ออีเมลสำเร็จ", {
            variant: "success",
            transitionDuration: { appear: 500, enter: 425, exit: 195 },
          });
          setOpen(false);
        } catch (error: any) {
          actions.setErrors({
            alert: translateErrors(error.graphQLErrors),
          });
        }
      }}
    >
      {(formikProps: FormikProps<ChangeMailValue>) => (
        <Box component="form" onSubmit={formikProps.handleSubmit}>
          <DialogContent sx={{ pt: 0, overflow: "hidden" }}>
            <FormAlert {...formikProps} />
            {getEmailNameTerms("body2")}
            <Box sx={{ my: 1 }}>
              <CustomTextField
                name="desiredPrefix"
                variant="outlined"
                inputProps={
                  isStudentIdPrefixNeeded
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            {internalId || "00000"}.
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
                {...formikProps}
              />
            </Box>
            {getEmailExample(
              `${isStudentIdPrefixNeeded ? (internalId || "00000") + "." : ""}${
                formikProps.values.desiredPrefix
              }`
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
            <LoadingButton loading={loading} type="submit" startIcon={<Save />}>
              บันทึก
            </LoadingButton>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
};

export default ChangeMailDialog;
