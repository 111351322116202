import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { DeleteForever } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import cancelAction from "../../../../../graphql/mutation/ADCancelAction.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import {
  ActionQueueType,
  SearchActionQueueRefetchType,
} from "../../../../../types/interfaces";
import getActionTypeStatus from "../../../../../utils/getActionTypeStatus";
import { combineName } from "../../../../../utils/table";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";

interface ActionQueueInfoDialogProps {
  data: ActionQueueType;
  refetch: (arg0: SearchActionQueueRefetchType) => void;
}

const ActionCancelDialog = ({ data, refetch }: ActionQueueInfoDialogProps) => {
  const [open, setOpen] = useState(false);
  const [cancel, { loading }] = useMutation(cancelAction);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancel = async () => {
    try {
      await cancel({ variables: { id: data.id } });
      handleClose();
      enqueueSnackbar("ยกเลิกการดำเนินการสำเร็จ", {
        variant: "success",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
      refetch({ filter: {} });
    } catch (e: any) {
      handleClose();
      enqueueSnackbar(translateErrors(e.graphQLErrors), {
        variant: "error",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
    }
  };

  return (
    <>
      <Button onClick={handleOpen} startIcon={<DeleteForever />} color="error">
        ยกเลิก
      </Button>
      <CustomDialog
        title="คุณต้องการยกเลิกการดำเนินการหรือไม่"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        <DialogContent sx={{ pt: 0 }}>
          <DialogContentText>
            คุณต้องการยกเลิกการ{getActionTypeStatus(data.actionType)} ของ{" "}
            {combineName(data.account)} หรือไม่
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
          <LoadingButton
            onClick={handleCancel}
            startIcon={<DeleteForever />}
            loading={loading}
            color="error"
          >
            ยกเลิก
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default ActionCancelDialog;
