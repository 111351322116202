import { gql } from "@apollo/client";

const resetPassword = gql`
  mutation ResetPassword(
    $accountId: ID!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    ADResetAccountPassword(
      accountId: $accountId
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      account {
        id
      }
    }
  }
`;
export default resetPassword;
