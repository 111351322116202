import { gql } from "@apollo/client";

const logoutUser = gql`
  mutation LogoutUser {
    LogoutMutation {
      result
    }
  }
`;
export default logoutUser;
