import * as React from "react";
import { Navigate } from "react-router";

import AdminLayout from "../components/Base/AdminLayout/AdminLayout";
import authenticatedOnly from "../components/Base/AuthenticatedOnly";
import notAuthenticatedAdminOnly from "../components/Base/NotAuthenticatedAdminOnly";
import notAuthenticatedOnly from "../components/Base/NotAuthenticatedOnly";
import UserLayout from "../components/Base/UserLayout/UserLayout";
import ActionQueue from "./Admin/ActionQueue/ActionQueue";
import AddUser from "./Admin/AddUser/AddUser";
import AdminLogin from "./Admin/Auth/AdminLogin";
import AdminDashboard from "./Admin/Dashboard/AdminDashboard";
import EditUser from "./Admin/EditUser/EditUser";
import ForgotPasswordQueue from "./Admin/ForgotPasswordQueue/ForgotPasswordQueue";
import Groups from "./Admin/Groups/Groups";
import Setting from "./Admin/Setting/Setting";
import UploadUser from "./Admin/UploadUser/UploadUser";
import Users from "./Admin/Users/Users";

const UserLogin = React.lazy(() => import("./User/Auth/UserLogin"));
const UserDashboard = React.lazy(
  () => import("./User/Dashboard/UserDashboard")
);
const ForgotPassword = React.lazy(
  () => import("./User/ForgotPassword/ForgotPassword")
);
const NotFound = React.lazy(() => import("./NotFound"));

const routes = [
  {
    path: "",
    element: <UserLayout />,
    children: [
      { path: "", element: notAuthenticatedOnly(UserLogin) },
      { path: "login", element: notAuthenticatedOnly(UserLogin) },
      { path: "user", element: authenticatedOnly(UserDashboard) },
      {
        path: "forgotpassword",
        element: notAuthenticatedOnly(ForgotPassword),
      },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "admin",
    element: <UserLayout />,
    exact: true,
    children: [{ path: "", element: notAuthenticatedAdminOnly(AdminLogin) }],
  },
  {
    path: "admin",
    element: <AdminLayout />,
    children: [
      { path: "dashboard", element: <AdminDashboard /> },
      { path: "actionqueue", element: <ActionQueue /> },
      { path: "forgotqueue", element: <ForgotPasswordQueue /> },
      { path: "user", element: <Users /> },
      { path: "user/create", element: <AddUser /> },
      { path: "user/edit/:id", element: <EditUser /> },
      { path: "user/upload", element: <UploadUser /> },
      { path: "group", element: <Groups /> },
      { path: "setting", element: <Setting /> },
    ],
  },
];

export default routes;
