import { gql } from "@apollo/client";

const addAccount = gql`
  mutation AddAccount(
    $firstName: String!
    $lastName: String!
    $joinedEducationYear: String!
    $joinedType: String!
    $identityType: String!
    $personIdentity: String!
    $username: String!
    $internalId: String
  ) {
    ADAddAccount(
      firstName: $firstName
      lastName: $lastName
      joinedEducationYear: $joinedEducationYear
      joinedType: $joinedType
      identityType: $identityType
      personIdentity: $personIdentity
      username: $username
      internalId: $internalId
    ) {
      account {
        id
      }
    }
  }
`;
export default addAccount;
