import * as React from "react";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { setSiteTitle } from "../../../dataStore/actions/SiteTitle";
import { Context } from "../../../dataStore/context";
import USGetAccount from "../../../graphql/query/USGetAccount.query";
import { UserDataComponentProps } from "../../../types/interfaces";
import PageLoading from "../../PageLoading";

const authenticatedOnly = (
  Component: React.ComponentType<UserDataComponentProps>
) => {
  const AuthenticatedOnlyComponent = () => {
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(USGetAccount, {
      fetchPolicy: "network-only",
      errorPolicy: "none",
    });
    const context = React.useContext(Context);

    React.useEffect(() => {
      if (error) navigate("/", { replace: true });
      if (data) setSiteTitle(context, data.USGetConfigs);
    }, [error, navigate, loading, context, data]);

    if (data) {
      return <Component data={data} />;
    }
    return <PageLoading />;
  };

  return <AuthenticatedOnlyComponent />;
};

export default authenticatedOnly;
