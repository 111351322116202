import { Avatar, Grid } from "@mui/material";
import CustomCard from "../../../CustomCard/CustomCard";
import Typography from "@mui/material/Typography";

interface DashboardSummaryProps {
  title: string;
  amount: string | number | undefined;
  classifier: string;
  iconColor: string;
  icon: JSX.Element;
}

const DashboardSummary = ({
  title,
  amount,
  classifier,
  iconColor,
  icon,
}: DashboardSummaryProps) => {
  return (
    <Grid item xs={12} md={6} lg={3}>
      <CustomCard title={title}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h3" component="p">
              {!!amount ? amount : 0}
            </Typography>
            <Typography variant="body2" component="p" color="textSecondary">
              {classifier}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Avatar
              sx={{
                backgroundColor: iconColor,
                float: "right",
                width: 48,
                height: 48,
              }}
            >
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CustomCard>
    </Grid>
  );
};

export default DashboardSummary;
