import { gql } from "@apollo/client";

const editConfigs = gql`
  mutation EditConfigs($configs: [GQLConfiguration]!) {
    ADEditConfigs(configs: $configs) {
      configs {
        id
        configKey
        value
      }
    }
  }
`;
export default editConfigs;
