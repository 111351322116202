import _ from "lodash";
import * as React from "react";
import { NavLink as RouterLink } from "react-router-dom";

import { ArrowRight } from "@mui/icons-material";
import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import { ColumnsType } from "../../types/interfaces";
import CustomCard from "../CustomCard/CustomCard";

interface RowsType {
  [index: string]: any;
}

interface ListTableProps {
  title: string;
  columns: ColumnsType[];
  rows: RowsType[] | undefined;
  pageRoute?: string;
  totalItems: number | undefined;
  refetch?: (arg0: any) => void;
  rowsPerPage?: number;
  needPagination?: boolean;
  isLoading: boolean;
}

interface TableRowsProps {
  data: RowsType[] | undefined;
}

const ListTable = ({
  title,
  columns,
  rows,
  isLoading,
  pageRoute,
  totalItems = 0,
  refetch,
  rowsPerPage = 20,
  needPagination = true,
}: ListTableProps) => {
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    if (!isLoading) {
      const getMaxPage = () => Math.ceil(totalItems / rowsPerPage);
      if (getMaxPage() <= 0) {
        setPage(0);
      } else if (page >= getMaxPage()) {
        setPage(getMaxPage() - 1);
      }
    }
  }, [totalItems, page, rowsPerPage, setPage, isLoading]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    if (!_.isUndefined(refetch)) refetch({ page: newPage + 1 });
    window.scrollTo(0, 100);
  };

  const TableRows = ({ data }: TableRowsProps): JSX.Element => {
    if (isLoading || !data) {
      let skeleton: JSX.Element[] = [];
      _.times(5, (i) =>
        skeleton.push(
          <TableRow hover key={i}>
            {columns.map((column, index) => (
              <TableCell key={index}>
                <Typography variant="h3">
                  <Skeleton />
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        )
      );
      return <>{skeleton}</>;
    }
    if (!_.isNil(data) && !_.isEmpty(data)) {
      return (
        <>
          {(needPagination && !refetch
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column, index) => {
                  const value = row[column.id];
                  return column.id === "button" ? (
                    <TableCell key={index} align={column.align}>
                      {value}
                    </TableCell>
                  ) : (
                    <TableCell key={index} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </>
      );
    }
    return (
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell key="0" colSpan={columns.length}>
          ไม่มีข้อมูล
        </TableCell>
      </TableRow>
    );
  };

  return (
    <CustomCard title={title}>
      <Table>
        {pageRoute && !needPagination && (
          <caption style={{ textAlign: "right", paddingBottom: 0 }}>
            <Button
              component={RouterLink}
              to={pageRoute}
              color="primary"
              endIcon={<ArrowRight />}
              size="small"
              variant="text"
            >
              ดูทั้งหมด
            </Button>
          </caption>
        )}
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                sx={{ backgroundColor: "white" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRows data={rows} />
        </TableBody>
      </Table>
      {needPagination && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page > Math.floor(totalItems / rowsPerPage) ? 0 : page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => {
            return `ข้อมูลแถวที่ ${from}-${to} จากทั้งหมด ${count} แถว`;
          }}
          sx={{ mt: 1, mb: -1 }}
        />
      )}
    </CustomCard>
  );
};

export default ListTable;
