import { gql } from "@apollo/client";

const unSuspendAccount = gql`
  mutation UnSuspendAccount($accountId: ID!) {
    ADUnSuspendAccount(accountId: $accountId) {
      account {
        id
      }
    }
  }
`;
export default unSuspendAccount;
