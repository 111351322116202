interface TextFieldListType {
  [index: string]: {
    label: string;
    name: string;
    type: string;
    helperText?: string;
  };
}

export const TextFieldList: TextFieldListType = {
  username: {
    label: "ชื่อผู้ใช้",
    name: "username",
    type: "text",
  },
  password: {
    label: "รหัสผ่าน",
    name: "password",
    type: "password",
  },
  desiredPrefix: {
    label: "ชื่ออีเมล",
    name: "desiredPrefix",
    type: "text",
  },
  oldPassword: {
    label: "รหัสผ่านเก่า",
    name: "oldPassword",
    type: "password",
  },
  newPassword: {
    label: "รหัสผ่านใหม่",
    name: "newPassword",
    type: "password",
  },
  confirmPassword: {
    label: "ยืนยันรหัสผ่านใหม่",
    name: "confirmPassword",
    type: "password",
  },
  internalId: {
    label: "รหัสนักเรียน",
    name: "internalId",
    type: "text",
  },
  birthDate: {
    label: "วันเดือนปีเกิด",
    name: "birthDate",
    helperText: "โปรดกรอกปีเกิดเป็นปีคริสต์ศักราช",
    type: "date",
  },
  personIdentity: {
    label: "เลขรหัสบัตรประชาชน",
    name: "personIdentity",
    type: "text",
  },
  mail: {
    label: "ชื่ออีเมล",
    name: "mail",
    type: "text",
  },
  createdAt: {
    label: "วันที่ขอ",
    name: "createdAt",
    type: "date",
  },
  updatedAt: {
    label: "วันที่ดำเนินการ",
    name: "updatedAt",
    type: "date",
  },
  doneAt: {
    label: "วันที่สำเร็จ",
    name: "doneAt",
    type: "date",
  },
  firstName: {
    label: "ชื่อจริง",
    name: "firstName",
    type: "text",
  },
  lastName: {
    label: "นามสกุล",
    name: "lastName",
    type: "text",
  },
  joinedEducationYear: {
    label: "ปีที่เข้าศึกษา",
    name: "joinedEducationYear",
    type: "number",
  },
  groupName: {
    label: "ชื่อกลุ่ม",
    name: "groupName",
    type: "text",
  },
  googleAdminAccountMail: {
    label: "บัญชีแอดมิน Google Account",
    name: "googleAdminAccountMail",
    type: "text",
  },
  googleCustomerID: {
    label: "รหัสลูกค้า Google",
    name: "googleCustomerID",
    type: "text",
  },
  googleAPIClientOAuthID: {
    label: "Google API Client OAuth ID",
    name: "googleAPIClientOAuthID",
    type: "text",
  },
  emailSuffixGoogle: {
    label: "Suffix ของอีเมล Google",
    name: "emailSuffixGoogle",
    type: "text",
  },
  microsoftClientID: {
    label: "รหัสลูกค้า Microsoft",
    name: "microsoftClientID",
    type: "text",
  },
  microsoftSecret: {
    label: "Microsoft Secret",
    name: "microsoftSecret",
    type: "text",
  },
  microsoftTenantID: {
    label: "Microsoft Tenant ID",
    name: "microsoftTenantID",
    type: "text",
  },
  emailSuffixMicrosoft: {
    label: "Suffix ของอีเมล Microsoft",
    name: "emailSuffixMicrosoft",
    type: "text",
  },
  recaptchaSecret: {
    label: "Recaptcha Secret",
    name: "recaptchaSecret",
    type: "text",
  },
  recaptchaSiteKey: {
    label: "Recaptcha Site Key",
    name: "recaptchaSiteKey",
    type: "text",
  },
  organizationName: {
    label: "ชื่อเว็บไซต์",
    name: "organizationName",
    type: "text",
  },
};
