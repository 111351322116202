import { Formik, FormikProps } from "formik";
import _ from "lodash";

import { RotateLeft, Search } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

import {
  GroupType,
  SearchUserFilterType,
  SearchUserRefetchType,
  SearchUserValue,
} from "../../../../types/interfaces";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import CustomTextField from "../../../TextFields/CustomTextField";

interface SearchActionQueueProps {
  loading: boolean;
  refetch: (arg0: SearchUserRefetchType) => void;
  groups?: GroupType[];
}

const SearchUser = ({ loading, refetch, groups }: SearchActionQueueProps) => {
  const initialValues: SearchUserValue = {
    firstName: "",
    lastName: "",
    isActive: "",
    isRegis: "",
    isStaff: "",
    mail: "",
    personIdentity: "",
    internalId: "",
    groupId: "",
    joinedEducationYear: "",
    hasPendingQueue: "",
  };

  return (
    <Grid item xs={12}>
      <CustomCard title="ค้นหาผู้ใช้งาน">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false);
            try {
              const filter: SearchUserFilterType = {};
              if (!_.isEmpty(values.firstName)) {
                filter["firstName"] = values.firstName;
              }
              if (!_.isEmpty(values.lastName)) {
                filter["lastName"] = values.lastName;
              }
              if (!_.isEmpty(values.isActive)) {
                filter["isActive"] = values.isActive === "active";
              }
              if (!_.isEmpty(values.isRegis)) {
                filter["isRegis"] = values.isRegis === "regis";
              }
              if (!_.isEmpty(values.isStaff)) {
                filter["isStaff"] = values.isStaff === "admin";
              }
              if (!_.isEmpty(values.mail)) {
                filter["mail"] = values.mail;
              }
              if (!_.isEmpty(values.personIdentity)) {
                filter["personIdentity"] = values.personIdentity;
              }
              if (!_.isEmpty(`${values.internalId}`)) {
                filter["internalId"] = values.internalId;
              }
              if (!_.isEmpty(values.groupId)) {
                filter["groupId"] = parseInt(values.groupId || "");
              }
              if (!_.isEmpty(`${values.joinedEducationYear}`)) {
                filter["joinedEducationYear"] = parseInt(
                  values.joinedEducationYear || ""
                );
              }
              if (!_.isEmpty(values.hasPendingQueue)) {
                filter["hasPendingQueue"] =
                  values.hasPendingQueue === "hasPendingQueue";
              }
              await refetch({ filter, page: 1 });
            } catch (error) {}
          }}
        >
          {(formikProps: FormikProps<SearchUserValue>) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="firstName"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="lastName"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="isActive"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="isRegis"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="isStaff"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="mail"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="personIdentity"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="internalId"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="groupId"
                    variant="outlined"
                    needNotSelected={true}
                    option={groups?.map((group) => ({
                      value: group.id,
                      label: group.name,
                    }))}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="joinedEducationYear"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>{" "}
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="hasPendingQueue"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "end" }}>
                    <LoadingButton
                      color="primary"
                      type="submit"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      startIcon={<Search />}
                      loading={loading}
                    >
                      ค้นหา
                    </LoadingButton>
                    <Button
                      type="reset"
                      variant="text"
                      startIcon={<RotateLeft />}
                      onClick={() => {
                        formikProps.handleReset();
                        refetch({ filter: {}, page: 1 });
                      }}
                    >
                      รีเซต
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </CustomCard>
    </Grid>
  );
};

export default SearchUser;
