import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { setSiteTitle } from "../../../dataStore/actions/SiteTitle";
import { Context } from "../../../dataStore/context";
import USGetAccount from "../../../graphql/query/USGetAccount.query";
import { UserDataComponentProps } from "../../../types/interfaces";
import PageLoading from "../../PageLoading";

const authenticatedAdminOnly = (
  Component: React.ComponentType<UserDataComponentProps>
) => {
  const AuthenticatedAdminOnlyComponent = () => {
    const navigate = useNavigate();
    const { data, error } = useQuery(USGetAccount, {
      fetchPolicy: "network-only",
    });
    const context = useContext(Context);

    useEffect(() => {
      if (error) navigate("/", { replace: true });
      if (data) setSiteTitle(context, data.USGetConfigs);
      if (data && data?.USGetAccount?.isStaff === false)
        navigate("/user", { replace: true });
    }, [data, error, navigate, context]);

    if (data && !error) {
      return <Component data={data} />;
    }
    return <PageLoading />;
  };
  return AuthenticatedAdminOnlyComponent;
};

export default authenticatedAdminOnly;
