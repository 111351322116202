import { useLocation } from "react-router-dom";

import { useEffect } from "react";
import {
  AccessTime,
  Home,
  People,
  Person,
  Settings,
} from "@mui/icons-material";
import { Box, Divider, List, Hidden, Drawer } from "@mui/material";
import Typography from "@mui/material/Typography";
import NavItem from "./NavItem";

const items = [
  {
    href: "/admin/dashboard",
    icon: <Home />,
    title: "หน้าแรก",
  },
  {
    href: "/admin/actionqueue",
    icon: <AccessTime />,
    title: "คิวการดำเนินการ",
  },
  {
    href: "/admin/forgotqueue",
    icon: <AccessTime />,
    title: "คำร้องขอรีเซ็ตรหัสผ่าน",
  },
  {
    href: "/admin/user",
    icon: <Person />,
    title: "รายชื่อผู้ใช้",
  },
  {
    href: "/admin/group",
    icon: <People />,
    title: "กลุ่มผู้ใช้",
  },
  {
    href: "/admin/setting",
    icon: <Settings />,
    title: "การตั้งค่า",
  },
];

interface AdminSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  name: string;
}

const AdminSidebar = ({
  onMobileClose,
  openMobile,
  name,
}: AdminSidebarProps) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Typography color="textPrimary" variant="h6">
          {name}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              border: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
              border: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AdminSidebar;
