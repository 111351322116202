import { gql } from "@apollo/client";

export const PERMISSION_FIELDS = gql`
  fragment PermissionFields on GQLPermissionType {
    id
    codename
    name
  }
`;

export const GROUP_FIELDS = gql`
  ${PERMISSION_FIELDS}
  fragment GroupFields on GQLGroupType {
    id
    name
    permissions {
      ...PermissionFields
    }
    groupConfig {
      isStudentIdPrefixNeeded
    }
  }
`;

export const ACCOUNT_FIELDS = gql`
  ${GROUP_FIELDS}
  fragment AccountFields on GQLAccountType {
    id
    username
    isSuperuser
    isStaff
    isActive
    lastLogin
    firstName
    lastName
    birthDate
    internalId
    personIdentity
    identityType
    microsoftMail
    googleMail
    joinedEducationYear
    joinedType
    hasPendingActions
    hasPendingCreateAccountAction
    isRegis
    sAMAccountName
    groups {
      ...GroupFields
    }
    permissions
  }
`;
