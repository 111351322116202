import { Dialog, DialogTitle, Paper, PaperProps } from "@mui/material";

interface CustomDialogProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  children: JSX.Element | JSX.Element[];
  maxWidth?: number;
}

const CustomDialog = ({
  title,
  open,
  handleClose,
  children,
  maxWidth = 360,
}: CustomDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperComponent={(p: PaperProps) => <Paper {...p} elevation={5} />}
      PaperProps={{
        style: {
          borderRadius: 2,
          maxWidth: maxWidth,
        },
      }}
      keepMounted={false}
    >
      {open ? (
        <>
          <DialogTitle color="primary">{title}</DialogTitle>
          {children}
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default CustomDialog;
