import { gql } from "@apollo/client";

const findGroups = gql`
  query FindGroups($name: String) {
    ADFindGroups(name: $name) {
      groups {
        id
        name
        permissions {
          id
          name
          codename
        }
        groupConfig {
          OU
          MSGroupid
          isStudentIdPrefixNeeded
          GoogleIsclassroomteacher
        }
      }
    }
    ADGoogleOUs {
      orgUnitId
      orgUnitPath
      name
    }
    ADGetMsGroups {
      id
      displayName
      onPremisesSecurityIdentifier
    }
    ADGetAllConfigs {
      configs {
        id
        configKey
        value
      }
    }
  }
`;

export default findGroups;
