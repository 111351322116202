import { Box } from "@mui/system";
import { Outlet } from "react-router";

const UserLayout = () => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default UserLayout;
