import { Box, CircularProgress } from "@mui/material";

import Title from "./Title";

const PageLoading = () => {
  return (
    <>
      <Title title="กำลังโหลด" />
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    </>
  );
};

export default PageLoading;
