import { Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";

import resetPassword from "../../../../../graphql/mutation/ADResetPassword.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import { ResetPasswordValue } from "../../../../../types/interfaces";
import { getPasswordTerm } from "../../../../../utils/getPasswordTerms";
import { ResetPasswordValid } from "../../../../../validations/AdminValidations/AdminValidations";
import { FormAlert } from "../../../../Alert/FormAlert";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";
import CustomTextField from "../../../../TextFields/CustomTextField";

interface RegisterMailDialogProps {
  id: string;
  refetch: () => void;
}

const ChangePasswordDialog = ({ id, refetch }: RegisterMailDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>เปลี่ยนรหัสผ่าน</Button>
      <CustomDialog
        title="เปลี่ยนรหัสผ่าน"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        {open ? (
          <RegisterMailForm id={id} refetch={refetch} setOpen={setOpen} />
        ) : (
          <></>
        )}
      </CustomDialog>
    </>
  );
};

interface RegisterMailFormProps {
  id: string;
  refetch: () => void;
  setOpen: (arg0: boolean) => void;
}

const RegisterMailForm = ({ id, refetch, setOpen }: RegisterMailFormProps) => {
  const [register, { loading }] = useMutation(resetPassword);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: ResetPasswordValue = {
    alert: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordValid}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(false);
        try {
          await register({
            variables: {
              accountId: id,
              newPassword: values.newPassword,
              confirmNewPassword: values.confirmPassword,
            },
          });
          await refetch();
          enqueueSnackbar("เปลี่ยนรหัสผ่านสำเร็จ", {
            variant: "success",
            transitionDuration: { appear: 500, enter: 425, exit: 195 },
          });
          setOpen(false);
        } catch (error: any) {
          actions.setErrors({
            alert: translateErrors(error.graphQLErrors),
          });
        }
      }}
    >
      {(formikProps: FormikProps<ResetPasswordValue>) => (
        <Box component="form" onSubmit={formikProps.handleSubmit}>
          <DialogContent sx={{ pt: 0, overflow: "hidden" }}>
            <FormAlert {...formikProps} />
            <DialogContentText>{getPasswordTerm(false)}</DialogContentText>
            <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
              <CustomTextField
                name="newPassword"
                variant="outlined"
                {...formikProps}
              />
              <CustomTextField
                name="confirmPassword"
                variant="outlined"
                {...formikProps}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
            <LoadingButton loading={loading} type="submit" startIcon={<Save />}>
              บันทึก
            </LoadingButton>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
};

export default ChangePasswordDialog;
