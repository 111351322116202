import { Check, Clear, ModeEdit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import {
  ColumnsType,
  RefetchPageType,
  UserType,
} from "../../../../types/interfaces";
import { combineName } from "../../../../utils/table";
import ListTable from "../../../ListTable/ListTable";

const columns: ColumnsType[] = [
  { id: "name", label: "ชื่อ-สกุล", minWidth: 100 },
  {
    id: "googleMail",
    label: "Google Account",
    minWidth: 100,
    align: "center",
  },
  {
    id: "microsoftMail",
    label: "Microsoft Account",
    minWidth: 100,
    align: "center",
  },
  { id: "isRegis", label: "การสมัครบริการ", minWidth: 100, align: "center" },
  {
    id: "hasPendingActions",
    label: "มีคิวดำเนินการ",
    minWidth: 100,
    align: "center",
  },
  { id: "button", label: "", minWidth: 50, align: "center" },
];

interface UserTableProps {
  userData: UserType[] | undefined;
  refetch: (arg0: RefetchPageType) => void;
  totalItems: number | undefined;
  needPagination?: boolean;
  rowsPerPage?: number;
  pageRoute?: string;
  isLoading: boolean;
}

const UserTable = ({
  userData,
  refetch,
  totalItems,
  needPagination = true,
  rowsPerPage = 20,
  pageRoute,
  isLoading,
}: UserTableProps) => {
  const showIcon = (action: boolean) =>
    action ? <Check color="success" /> : <Clear color="error" />;
  const renderButton = (id: string) => (
    <Button
      startIcon={<ModeEdit />}
      to={`/admin/user/edit/${id}`}
      component={Link}
    >
      แก้ไข
    </Button>
  );

  let rows;

  if (!!userData) {
    rows = userData.map((row) => {
      return {
        id: row.id,
        name: combineName(row),
        googleMail: row.googleMail || "-",
        microsoftMail: row.microsoftMail || "-",
        isRegis: showIcon(row.isRegis),
        hasPendingActions: showIcon(row.hasPendingActions),
        button: renderButton(row.id),
      };
    });
  }

  return (
    <ListTable
      title="ผู้ใช้ทั้งหมด"
      columns={columns}
      rows={rows}
      refetch={refetch}
      totalItems={totalItems}
      rowsPerPage={rowsPerPage}
      needPagination={needPagination}
      pageRoute={pageRoute}
      isLoading={isLoading}
    />
  );
};

export default UserTable;
