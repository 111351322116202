import { JoinedTypesType } from "../types/interfaces";

export const JOINED_TYPE: JoinedTypesType = {
  TEACHER: "ครู",
  PERSONNEL: "บุคลากร",
  M1: "นักเรียน ม.1",
  M2: "นักเรียน ม.2",
  M3: "นักเรียน ม.3",
  M4: "นักเรียน ม.4",
  M5: "นักเรียน ม.5",
  M6: "นักเรียน ม.6",
  OTHER: "อื่น ๆ",
};
