interface Error {
  [index: string]: string;
}

const ERRORS: Error = {
  THAI_ID_INVALID: "รหัสบัตรประชาชนไม่ถูกต้อง",
  THAI_ID_INVALID_MSG: "รหัสบัตรประชาชนไม่ถูกต้อง",
  GOOGLE_MAIL_INVALID: "อีเมลไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง",
  GOOGLE_MAIL_INVALID_MSG: "อีเมลไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง",
  MICROSOFT_MAIL_INVALID: "อีเมลไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง",
  MICROSOFT_MAIL_INVALID_MSG: "อีเมลไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง",
  PASSWORD_WEAK: "รหัสผ่านง่ายต่อการคาดเดามากเกินไป",
  PASSWORD_MISMATCH: "รหัสผ่านไม่ตรงกัน",
  LOGIN_FAILED: "ไม่สามารถเข้าสู่ระบบ โปรดตรวจสอบชื่อผู้ใช้งานและรหัสผ่าน",
  ACCOUNT_NOT_FOUND: "ไม่พบบัญชีผู้ใช้งาน",
  ALREADY_REGISTERED: "ลงทะเบียนใช้งานอีเมล Google และ Microsoft ไปแล้ว",
  HAS_PENDING_ACTION:
    "มีการดำเนินการค้างอยู่ภายในระบบ โปรดกลับมาอีกครั้งในภายหลัง",
  HAS_PENDING_CREATE_ACTION:
    "มีการดำเนินการขอลงทะเบียนบัญชี Google และ Microsoft ค้างอยู่ในระบบ โปรดกลับมาในภายหลัง",
  IS_NOT_STUDENT: "บัญชี้นี้ไม่ใช่นักเรียน",
  OLD_PASSWORD_INCORRECT: "รหัสผ่านเก่าไม่ถูกค้อง",
  EMAIL_DUPLICATE: "อีเมลนี้ถูกใช้ไปแล้ว โปรดเลือกชื่ออีเมลอื่น",
  USERNAME_EXIST: "ชื่อผูัใช้นี้ถูกใช้ไปแล้ว",
  PERSON_IDENTITY_EXIST: "เลขบัตรประชาชนนี้ถูกใช้ไปแล้ว",
  USER_IN_PASSWORD: 'รหัสผ่านไม่สามารถมีส่วนใดส่วนหนึ่งเป็น Username หรืออีเมลได้',
};

export default ERRORS;
