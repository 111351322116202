import { gql } from "@apollo/client";
import { ACCOUNT_FIELDS } from "../fragments";

const ADDashboard = gql`
  ${ACCOUNT_FIELDS}
  query ADDashboard {
    ADGetDashboard {
      totalUser
      actionQueueToday
      actionQueueLeft
      passwordRequest
    }
    ADGetPendingPasswordResetRequest {
      totalPages
      totalItems
      forgotQueues {
        account {
          ...AccountFields
        }
        id
        status
        createdAt
      }
    }
    ADGetWaitingActionQueue {
      totalPages
      totalItems
      actionQueues {
        id
        actionType
        createdAt
        lastAttemptAt
        attempt
        status
        account {
          ...AccountFields
        }
      }
    }
  }
`;
export default ADDashboard;
