import { Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { UserType, USGetConfigsType } from "../../../../types/interfaces";
import { combineName } from "../../../../utils/table";
import CustomCard from "../../../CustomCard/CustomCard";
import ChangeMailDialog from "./Dialogs/ChangeMailDialog";
import ChangePasswordDialog from "./Dialogs/ChangePasswordDialog";
import DeleteUserDialog from "./Dialogs/DeleteUserDialog";
import RegisterMailDialog from "./Dialogs/RegisterMailDialog";
import SuspendUserDialog from "./Dialogs/SuspendUserDialog";
import UnSuspendUserDialog from "./Dialogs/UnSuspendUserDialog";
import EditUserServicesList from "./EditUserServicesList";

interface EditUserInfoCardProps {
  userData: UserType;
  configs: USGetConfigsType[];
  refetch: () => void;
}

const showData = (value: string | undefined) => (!!value ? value : "-");

const EditUserInfoCard = ({
  userData,
  configs,
  refetch,
}: EditUserInfoCardProps) => {
  return (
    <CustomCard title="จัดการบัญชีผู้ใช้">
      <Typography variant="caption" color="textSecondary">
        ชื่อ-สกุล
      </Typography>
      <Typography
        variant="h5"
        color={userData.isActive ? "textPrimary" : "error.main"}
        gutterBottom
      >
        {combineName(userData)}
      </Typography>
      <EditUserServicesList userData={userData} configData={configs} />
      <Divider />
      <Typography variant="caption" color="textSecondary">
        กลุ่มผู้ใช้
      </Typography>
      <Typography variant="body1" gutterBottom>
        {showData(userData.groups[0]?.name)}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="column" alignItems="flex-start">
        {userData.isRegis ? (
          <ChangeMailDialog
            id={userData.id}
            refetch={refetch}
            internalId={userData.internalId}
            isStudentIdPrefixNeeded={
              !!userData.groups[0]?.groupConfig.isStudentIdPrefixNeeded
            }
          />
        ) : (
          <RegisterMailDialog
            id={userData.id}
            refetch={refetch}
            internalId={userData.internalId}
            isStudentIdPrefixNeeded={
              !!userData.groups[0]?.groupConfig.isStudentIdPrefixNeeded
            }
          />
        )}
        <ChangePasswordDialog id={userData.id} refetch={refetch} />
        {userData.isActive ? (
          <SuspendUserDialog data={userData} refetch={refetch} />
        ) : (
          <UnSuspendUserDialog data={userData} refetch={refetch} />
        )}
        <DeleteUserDialog data={userData} />
      </Stack>
    </CustomCard>
  );
};

export default EditUserInfoCard;
