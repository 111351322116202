import { useContext } from "react";
import { Helmet } from "react-helmet";

import { Context } from "../dataStore/context";
import { getSiteTitle } from "../dataStore/actions/SiteTitle";

const Title = ({ title }: { title: string }) => {
  const context = useContext(Context);
  const getTitle = getSiteTitle(context);

  const displayTitle = () => (getTitle ? `| ${getTitle}` : "");

  return (
    <Helmet>
      <title>
        {title} {displayTitle()}
      </title>
    </Helmet>
  );
};

export default Title;
