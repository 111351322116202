import { gql } from "@apollo/client";

const editGroup = gql`
  mutation EditGroup(
    $id: ID!
    $GoogleOU: String!
    $MicrosoftGroup: String!
    $name: String!
    $isStudentIdPrefixNeeded: Boolean!
    $GoogleIsclassroomteacher: Boolean!
  ) {
    ADEditGroup(
      GoogleOU: $GoogleOU
      MicrosoftGroup: $MicrosoftGroup
      name: $name
      id: $id
      isStudentIdPrefixNeeded: $isStudentIdPrefixNeeded
      GoogleIsclassroomteacher: $GoogleIsclassroomteacher
    ) {
      group {
        id
        name
      }
    }
  }
`;

export default editGroup;
