import _ from "lodash";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const getEmailNameTerms = (variant: "body1" | "body2" | "caption") => {
  return (
    <Typography
      variant={variant}
      color="textSecondary"
      sx={{ display: "block", mb: 1 }}
    >
      <Box
        sx={{ fontWeight: "500", textDecoration: "underline" }}
        component="span"
      >
        ข้อกำหนดอีเมล:
      </Box>
      <br />
      1. ชื่ออีเมลต้องนำหน้าด้วยตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น
      <br />
      2. ชื่ออีเมลสามารถประกอบไปด้วยตัวอักษรภาษาอังกฤษ ตัวเลข
      และเครื่องหมายจุดเท่านั้น โดยจะต้องมีความยาวไม่เกิน 15 ตัวอักษร
    </Typography>
  );
};

const getEmailExample = (value: string) => {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{ mx: 2, display: "block" }}
    >
      <Box sx={{ fontWeight: "500", textDecoration: "underline" }}>
        ตัวอย่างอีเมล:
      </Box>
      {_.isNil(value) ? "" : value}
      @phoom.ac.th
    </Typography>
  );
};

export { getEmailNameTerms, getEmailExample };
