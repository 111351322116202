import { useState } from "react";

import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { ActionQueueType } from "../../../../../types/interfaces";
import { toThaiDateString, toTime } from "../../../../../utils/date";
import getActionQueueStatus from "../../../../../utils/getActionQueueStatus";
import getActionTypeStatus from "../../../../../utils/getActionTypeStatus";
import { combineName } from "../../../../../utils/table";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";

interface ActionQueueInfoDialogProps {
  data: ActionQueueType;
}

interface InfoTextFieldProps {
  label: string;
  value: string;
}

const InfoText = ({ label, value }: InfoTextFieldProps) => (
  <Stack>
    <Typography color="textSecondary" variant="caption">
      {label}
    </Typography>
    <Typography variant="body1" gutterBottom>
      {!!value ? value : "ไม่มีข้อมูล"}
    </Typography>
  </Stack>
);

const ActionQueueInfoDialog = ({ data }: ActionQueueInfoDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const info: { title: string; value: string }[] = [
    { title: "เจ้าของการดำเนินการ", value: combineName(data.account) },
    { title: "การดำเนินการ", value: getActionTypeStatus(data.actionType) },
    { title: "สถานะ", value: getActionQueueStatus(data.status) },
    {
      title: "วัน-เวลาที่เพิ่ม",
      value: `${toThaiDateString(data.createdAt)} เวลา ${toTime(
        data.createdAt
      )}`,
    },
    {
      title: "ดำเนินการครั้งล่าสุดเมื่อ",
      value: `${toThaiDateString(data.lastAttemptAt)} เวลา ${toTime(
        data.lastAttemptAt
      )}`,
    },
    {
      title: "วัน-เวลาที่ดำเนินการสำเร็จ",
      value: `${toThaiDateString(data.doneAt)} เวลา ${toTime(data.doneAt)}`,
    },
    { title: "จำนวนครั้งที่ดำเนินการ", value: data.attempt },
    { title: "Google Account", value: data.googleMail },
    { title: "Microsoft Account", value: data.microsoftMail },
    { title: "ข้อผิดพลาด", value: data.error },
  ];

  return (
    <>
      <Button onClick={handleOpen} startIcon={<Visibility />} sx={{ mr: 1 }}>
        ดูข้อมูล
      </Button>
      <CustomDialog
        title="ข้อมูลผู้ใช้"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        <DialogContent sx={{ pt: 0 }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {info.map((value, index) => (
              <InfoText label={value.title} value={value.value} key={index} />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default ActionQueueInfoDialog;
