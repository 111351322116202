import { Box, Typography } from "@mui/material";

interface CustomCardProps {
  title: string;
  children: React.ReactNode;
}

const CustomCard = ({ title, children, ...props }: CustomCardProps) => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        px: 3,
        py: 2,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default CustomCard;
