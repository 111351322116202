import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";

import SettingGoogle from "../../../components/Base/AdminLayout/Setting/SettingGoogle";
import SettingMicrosoft from "../../../components/Base/AdminLayout/Setting/SettingMicrosoft";
import SettingRecaptcha from "../../../components/Base/AdminLayout/Setting/SettingRecaptcha";
import SettingSite from "../../../components/Base/AdminLayout/Setting/SettingSite";
import PageError from "../../../components/PageError";
import PageLoading from "../../../components/PageLoading";
import Title from "../../../components/Title";
import editConfigs from "../../../graphql/mutation/ADEditConfig.mutation";
import getAllConfigs from "../../../graphql/query/ADGetAllConfigs.query";
import { ADSettingQuery, SettingID } from "../../../types/interfaces";

type ConfigType = {
  [key in keyof typeof SettingID]?: {
    value: string;
    id: SettingID;
  };
};

const Setting = () => {
  const { data, loading, error } = useQuery<ADSettingQuery>(getAllConfigs, {
    fetchPolicy: "network-only",
  });
  const [updateConfig, { loading: isUpdating }] = useMutation(editConfigs);

  if (loading) return <PageLoading />;
  if (error) return <PageError />;

  const configs: ConfigType = {};
  if (data) {
    data.ADGetAllConfigs.configs.map((config) =>
      Object.assign(configs, {
        [config.configKey]: { value: config.value, id: config.id },
      })
    );
  }

  return (
    <>
      <Title title="การตั้งค่า" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SettingSite
            organizationName={configs?.ORGANIZATION_NAME}
            sidebarGuide={configs?.SIDEBAR_GUIDE}
            isGoogleEnabled={configs?.GOOGLE_ENABLE}
            isMicrosoftEnabled={configs?.MICROSOFT_ENABLE}
            isIschoolEnabled={configs?.ISCHOOL_ENABLE}
            isActiveDirectoryEnabled={configs?.AD_ENABLE}
            updateConfig={updateConfig}
            isUpdating={isUpdating}
          />
        </Grid>
        {configs?.GOOGLE_ENABLE?.value.toLocaleLowerCase() === "true" && (
          <Grid item xs={12}>
            <SettingGoogle
              googleAdminAccountMail={configs?.GOOGLE_ADMIN_ACCOUNT_MAIL}
              googleCustomerID={configs?.GOOGLE_CUSTOMER_ID}
              googleAPIClientOAuthID={configs?.GOOGLE_API_CLIENT_OAUTH_SECRET}
              emailSuffixGoogle={configs?.EMAIL_SUFFIX_GOOGLE}
              googleClassroomTeacherGroup={
                configs?.GOOGLE_CLASSROOM_TEACHER_GROUP
              }
              googleGroups={data?.ADGoogleGroups}
              updateConfig={updateConfig}
              isUpdating={isUpdating}
            />
          </Grid>
        )}
        {configs?.MICROSOFT_ENABLE?.value.toLocaleLowerCase() === "true" && (
          <Grid item xs={12}>
            <SettingMicrosoft
              microsoftClientID={configs?.MICROSOFT_CLIENT_ID}
              microsoftSecret={configs?.MICROSOFT_SECRET}
              microsoftTenantID={configs?.MICROSOFT_TENANT_ID}
              emailSuffixMicrosoft={configs?.EMAIL_SUFFIX_MICROSOFT}
              updateConfig={updateConfig}
              isUpdating={isUpdating}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SettingRecaptcha
            recaptchaSecret={configs?.RECAPTCHA_SECRET}
            recaptchaSiteKey={configs?.RECAPTCHA_SITE_KEY}
            updateConfig={updateConfig}
            isUpdating={isUpdating}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Setting;
