import { gql } from "@apollo/client";

const getAllConfigs = gql`
  query GetAllConfigs {
    ADGetAllConfigs {
      configs {
        id
        configKey
        value
      }
    }
    ADGoogleGroups {
      id
      name
    }
  }
`;
export default getAllConfigs;
