import ACTION_TYPE_STATUS from "../graphql/ActionTypeStatus";
import { ActionTypeStatusType } from "../types/interfaces";

const getActionTypeStatus = (status: keyof ActionTypeStatusType) => {
  try {
    if (ACTION_TYPE_STATUS[status] === undefined) {
      return ACTION_TYPE_STATUS["UNKNOWN"];
    }
    return ACTION_TYPE_STATUS[status];
  } catch {
    return ACTION_TYPE_STATUS["UNKNOWN"];
  }
};

export default getActionTypeStatus;
