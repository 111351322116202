interface CheckboxListType {
  [index: string]: {
    label: string;
  };
}

export const CheckboxList: CheckboxListType = {
  isGoogleEnabled: {
    label: "เปิดใช้งานร่วมกับ Google Account",
  },
  isMicrosoftEnabled: {
    label: "เปิดใช้งานร่วมกับ Microsoft Account",
  },
  isIschoolEnabled: {
    label: "เปิดใช้งานร่วมกับ ISchool",
  },
  isActiveDirectoryEnabled: {
    label: "เปิดใช้งานร่วมกับ Active Directory",
  },
};
