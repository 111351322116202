import { gql } from "@apollo/client";

const importUsers = gql`
  mutation ImportUsers($accounts: [GQLImportAccountTypeAccount]!) {
    ADImportAccount(accounts: $accounts) {
      accounts {
        id
      }
    }
  }
`;

export default importUsers;
