import { ApolloError } from "@apollo/client";
import { Alert, AlertTitle, Box, Button, Modal } from "@mui/material";

import translateErrors from "../../../../graphql/utils/translateErrors";

interface UploadSuccessFailModalProps {
  isDone: boolean;
  setDone: (value: boolean) => void;
  status: string;
  error: ApolloError;
}

const UploadSuccessFailModal = ({
  isDone,
  setDone,
  status,
  error,
}: UploadSuccessFailModalProps) => {
  const handleClose = () => {
    setDone(false);
    window.location.reload();
  };

  return isDone ? (
    <div>
      <Modal
        open={isDone}
        onClose={handleClose}
        aria-labelledby="modal-upload-success"
        aria-describedby="modal-upload-complete-can-close"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {status === "success" ? (
            <Alert severity="success" sx={{ bgcolor: "#fff", p: 0 }}>
              <AlertTitle>อัปโหลดสำเร็จ</AlertTitle>
              ท่านสามารถปิดหน้านี้ได้เลย
              หากต้องการอัปโหลดผูัใช้เพิ่มเติมให้ทำการกดปุ่มปิด
            </Alert>
          ) : (
            <Alert
              severity="error"
              sx={{
                bgcolor: "#fff",
                p: 0,
              }}
            >
              <AlertTitle>เกิดข้อผิดพลาด</AlertTitle>
              สาเหตุ: {translateErrors(error.graphQLErrors)}
            </Alert>
          )}
          <Button
            variant="outlined"
            onClick={() => window.location.reload()}
            sx={{ width: "fit-content", margin: "auto" }}
          >
            ปิด
          </Button>
        </Box>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default UploadSuccessFailModal;
