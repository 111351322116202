import { gql } from "@apollo/client";

const cancelAction = gql`
  mutation CancelAction($id: UUID!) {
    ADCancelActionQueue(id: $id) {
      actionQueue {
        id
      }
    }
  }
`;

export default cancelAction;
