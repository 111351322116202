import { Formik, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { Login } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";

import LoginUser from "../../../../graphql/mutation/USLogin.mutation";
import translateErrors from "../../../../graphql/utils/translateErrors";
import { LoginValues } from "../../../../types/interfaces";
import { LoginValid } from "../../../../validations/GlobalValidation";
import { FormAlert } from "../../../Alert/FormAlert";
import AuthCard from "../../../AuthCard/AuthCard";
import CustomTextField from "../../../TextFields/CustomTextField";

const AdminLoginForm: React.FC<{}> = () => {
  const [loginUser, { loading }] = useMutation(LoginUser);
  const navigate = useNavigate();

  const initialValues: LoginValues = { alert: "", username: "", password: "" };

  return (
    <AuthCard title="เข้าสู่ระบบผู้ดูแล">
      <Formik
        initialValues={initialValues}
        validationSchema={LoginValid}
        onSubmit={async (values: LoginValues, actions) => {
          actions.setSubmitting(false);
          try {
            await loginUser({
              variables: {
                username: values.username,
                password: values.password,
              },
            });
            navigate("/admin/dashboard", { replace: true });
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps: FormikProps<LoginValues>) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <Box
              sx={{
                "& .MuiTextField-root": { my: 1 },
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box sx={{ my: 1 }}>
                <FormAlert {...formikProps} />
              </Box>
              <CustomTextField
                name="username"
                variant="standard"
                {...formikProps}
              />
              <CustomTextField
                name="password"
                variant="standard"
                {...formikProps}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<Login />}
                type="submit"
                loading={loading}
                loadingPosition="center"
                fullWidth
              >
                เข้าสู่ระบบ
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </AuthCard>
  );
};

export default AdminLoginForm;
