import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";

import CreateGroupDialog from "../../../components/Base/AdminLayout/Groups/Dialogs/CreateGroupDialog";
import GroupTable from "../../../components/Base/AdminLayout/Groups/GroupTable";
import PageError from "../../../components/PageError";
import Title from "../../../components/Title";
import findGroups from "../../../graphql/query/ADFindGroup.query";
import { ADFindGroupQuery, SettingID } from "../../../types/interfaces";

type ConfigType = {
  [key in keyof typeof SettingID]?: {
    value: string;
    id: SettingID;
  };
};

const Groups = () => {
  const { loading, data, refetch } = useQuery<ADFindGroupQuery>(findGroups, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  if (!loading && !data) return <PageError />;

  const configs: ConfigType = {};
  if (data) {
    data.ADGetAllConfigs.configs.map((config) =>
      Object.assign(configs, {
        [config.configKey]: { value: config.value, id: config.id },
      })
    );
  }

  return (
    <>
      <Title title="กลุ่มผู้ใช้" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CreateGroupDialog
            isLoading={loading}
            refetch={refetch}
            configs={configs}
            MsGroups={data?.ADGetMsGroups}
            GoogleOU={data?.ADGoogleOUs}
          />
        </Grid>
        <Grid item xs={12}>
          <GroupTable
            isLoading={loading}
            data={data}
            configs={configs}
            refetch={refetch}
            totalItems={data?.ADFindGroups?.totalItems}
            needPagination={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Groups;
