import FORGOT_PASSWORD_STATUS from "../graphql/ForgotPasswordStatus";
import { ForgotPasswordStatusType } from "../types/interfaces";

const getForgotPasswordStatus = (status: keyof ForgotPasswordStatusType) => {
  try {
    if (FORGOT_PASSWORD_STATUS[status] === undefined) {
      return "-";
    }
    return FORGOT_PASSWORD_STATUS[status];
  } catch {
    return "-";
  }
};

export default getForgotPasswordStatus;
