import { Typography, Box } from "@mui/material";
const getPasswordTerm = (isCaption: boolean = false) => {
  return !isCaption ? (
    "รหัสผ่านต้องประกอบไปด้วยตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข เครื่องหมายพิเศษ และต้องมีความยาวกว่า 8 ตัวอักษรเท่านั้น"
  ) : (
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{ display: "block" }}
    >
      <Box
        sx={{ fontWeight: "500", textDecoration: "underline" }}
        component="span"
      >
        ข้อกำหนดรหัสผ่าน:
      </Box>
      <br />
      รหัสผ่านต้องประกอบไปด้วยตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข เครื่องหมายพิเศษ
      และต้องมีความยาวกว่า 8 ตัวอักษรเท่านั้น
    </Typography>
  );
};

export { getPasswordTerm };
