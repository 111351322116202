import { gql } from "@apollo/client";
import { ACCOUNT_FIELDS } from "../fragments";

const LoginUser = gql`
  ${ACCOUNT_FIELDS}
  mutation LoginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      account {
        ...AccountFields
      }
    }
  }
`;

export default LoginUser;
