import { FormikProps, FormikValues } from "formik";

import {
  InputProps,
  FilledInputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";

import { TextFieldList } from "./TextFieldList";

interface CustomTextFieldProps {
  name: string;
  label?: string;
  variant?: "outlined" | "standard" | "filled";
  inputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
}

const CustomTextField = (
  props: CustomTextFieldProps &
    Omit<
      FormikProps<FormikValues>,
      "setFormikState" | "setValues" | "resetForm"
    >
) => {
  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    name,
    label,
    variant,
    inputProps,
  } = props;

  let inputLabel = {};
  if (TextFieldList[name].type === "date") inputLabel = { shrink: true };

  return (
    <TextField
      error={Boolean(
        touched[name as keyof typeof touched] &&
          errors[name as keyof typeof errors]
      )}
      fullWidth
      helperText={
        TextFieldList[name].helperText
          ? errors[name as keyof typeof errors] ||
            TextFieldList[name].helperText
          : touched[name as keyof typeof touched] &&
            errors[name as keyof typeof errors]
      }
      label={label || TextFieldList[name].label}
      name={TextFieldList[name].name}
      onBlur={handleBlur}
      onChange={handleChange}
      type={TextFieldList[name].type}
      value={values[name as keyof typeof values]}
      variant={variant}
      size="small"
      InputProps={inputProps}
      InputLabelProps={{ ...inputLabel }}
    />
  );
};

export default CustomTextField;
