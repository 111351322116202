import { useState } from "react";
import { Outlet } from "react-router";

import { styled } from "@mui/material";

import { UserDataType } from "../../../types/interfaces";
import { combineName } from "../../../utils/table";
import Navbar from "../../Navbar";
import authenticatedAdminOnly from "../AuthenticatedAdminOnly/AuthenticatedAdminOnly";
import AdminSidebar from "./AdminSidebar";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  margin: theme.spacing(4, 2),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
    margin: theme.spacing(5, 6),
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "0.6em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: 12,
  },
});

interface AdminLayoutProps {
  data: UserDataType;
}

const AdminLayout = ({ data }: AdminLayoutProps) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <Navbar onMobileNavOpen={() => setMobileNavOpen(true)} isAdmin />
      <AdminSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        name={combineName(data.USGetAccount)}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default authenticatedAdminOnly(AdminLayout);
