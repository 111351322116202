import * as React from "react";

import { Box, Typography } from "@mui/material";

interface AuthCardProps {
  title: string;
  children: React.ReactNode;
}

const AuthCard = ({ title, children }: AuthCardProps) => {
  return (
    <Box
      sx={{
        mb: { xs: 2 },
        bgcolor: "background.paper",
        boxShadow:
          "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;",
      }}
    >
      <Box sx={{ py: 4, px: 3 }}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default AuthCard;
