import { Box, Container } from "@mui/material";

import AdminLoginForm from "../../../components/Base/AdminLayout/Auth/AdminLoginForm";
import Title from "../../../components/Title";

const AdminLogin: React.FC<{}> = () => {
  return (
    <>
      <Title title="เข้าสู่ระบบผู้ดูแล" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <Container maxWidth="xs">
          <AdminLoginForm />
        </Container>
      </Box>
    </>
  );
};

export default AdminLogin;
