import { Formik } from "formik";

import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import translateErrors from "../../../../graphql/utils/translateErrors";
import { SettingID, SettingType } from "../../../../types/interfaces";
import { EditRecaptchaSettingValid } from "../../../../validations/AdminValidations/AdminValidations";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomTextField from "../../../TextFields/CustomTextField";

interface SettingRecaptchaProps {
  recaptchaSecret?: {
    id: SettingID;
    value: string;
  };
  recaptchaSiteKey?: {
    id: SettingID;
    value: string;
  };
  updateConfig: any;
  isUpdating: boolean;
}

interface initialValuesType {
  alert: string;
  recaptchaSecret: string;
  recaptchaSiteKey: string;
}

const SettingRecaptcha = ({
  recaptchaSecret,
  updateConfig,
  recaptchaSiteKey,
  isUpdating,
}: SettingRecaptchaProps) => {
  const initialValues: initialValuesType = {
    alert: "",
    recaptchaSecret: recaptchaSecret?.value || "",
    recaptchaSiteKey: recaptchaSiteKey?.value || "",
  };

  return (
    <CustomCard title="การตั้งค่า Recaptcha">
      <Formik
        initialValues={initialValues}
        validationSchema={EditRecaptchaSettingValid}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          actions.setStatus("");
          try {
            const variables: SettingType = { configs: [] };
            if (!!recaptchaSecret && !!recaptchaSiteKey) {
              if (!(values.recaptchaSecret === recaptchaSecret.value)) {
                variables.configs.push({
                  configKey: "RECAPTCHA_SECRET",
                  id: recaptchaSecret.id,
                  value: values.recaptchaSecret,
                });
              }
              if (!(values.recaptchaSiteKey === recaptchaSiteKey.value)) {
                variables.configs.push({
                  configKey: "RECAPTCHA_SITE_KEY",
                  id: recaptchaSiteKey.id,
                  value: values.recaptchaSiteKey,
                });
              }
            }
            updateConfig({ variables }).then(() => {
              actions.setStatus("แก้ไขการตั้งค่า Google Account สำเร็จ");
            });
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="recaptchaSecret"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="recaptchaSiteKey"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isUpdating}
              >
                บันทึก
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </CustomCard>
  );
};

export default SettingRecaptcha;
