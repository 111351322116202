import ACTION_QUEUE_STATUS from "../../graphql/ActionQueueStatus";
import ACTION_TYPE_STATUS from "../../graphql/ActionTypeStatus";
import FORGOT_PASSWORD_STATUS from "../../graphql/ForgotPasswordStatus";
import IDENTITY_TYPE from "../../graphql/IdentityTypes";
import { JOINED_TYPE } from "../../graphql/JoinedTypes";

interface CustomSelectType {
  [index: string]: {
    label: string;
    id: string;
    option: {
      value?: string;
      label?: string;
    }[];
  };
}

export const CustomSelectList: CustomSelectType = {
  forgotUserType: {
    label: "ประเภทผู้ใช้งาน",
    id: "forgot-user-type",
    option: [
      { value: "student", label: "นักเรียน" },
      { value: "teacher", label: "คุณครู" },
    ],
  },
  actionType: {
    label: "การดำเนินการ",
    id: "action-type",
    option: Object.entries(ACTION_TYPE_STATUS).map((value) => {
      if (value[0] !== "UNKNOWN") return { value: value[0], label: value[1] };
      else return {};
    }),
  },
  actionStatus: {
    label: "สถานะ",
    id: "action-status",
    option: Object.entries(ACTION_QUEUE_STATUS).map((value) => ({
      value: value[0],
      label: value[1],
    })),
  },
  forgotStatus: {
    label: "สถานะ",
    id: "forgot-status",
    option: Object.entries(FORGOT_PASSWORD_STATUS).map((value) => ({
      value: value[0],
      label: value[1],
    })),
  },
  isActive: {
    label: "ใช้งานได้",
    id: "is-active",
    option: [
      { value: "active", label: "ใช้งานได้" },
      { value: "disable", label: "ใช้งานไม่ได้" },
    ],
  },
  isRegis: {
    label: "สถานะผู้ใช้",
    id: "is-regis",
    option: [
      { value: "regis", label: "สมัครแล้ว" },
      { value: "notRegis", label: "ยังไม่ได้สมัคร" },
    ],
  },
  isStaff: {
    label: "ประเภทผู้ใช้งาน",
    id: "is-staff",
    option: [
      { value: "user", label: "ผู้ใช้งานทั่วไป" },
      { value: "admin", label: "ผู้ดูแลระบบ" },
    ],
  },
  groupId: {
    label: "กลุ่มผู้ใช้งาน",
    id: "group-id",
    option: [],
  },
  hasPendingQueue: {
    label: "มีคิวดำเนินการ",
    id: "has-pending-queue",
    option: [
      { value: "hasPendingQueue", label: "มีคิวดำเนินการ" },
      { value: "notHasPendingQueue", label: "ไม่มีคิวดำเนินการ" },
    ],
  },
  identityType: {
    label: "ประเภทบัตรประชาชน",
    id: "identity-type",
    option: Object.entries(IDENTITY_TYPE).map((value) => ({
      value: value[0],
      label: value[1],
    })),
  },
  joinedType: {
    label: "ระดับผู้ใช้งาน",
    id: "joined-type",
    option: Object.entries(JOINED_TYPE).map((value) => ({
      value: value[0],
      label: value[1],
    })),
  },
  googleClassroomTeacherGroup: {
    label: "Google Group ที่มีสิทธิ์ครูใน Google Classroom",
    id: "google-classroom-teacher-group",
    option: [],
  },
};
