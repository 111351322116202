import { Formik, FormikProps } from "formik";
import _ from "lodash";

import { useMutation } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from "@mui/material";

import editAccount from "../../../../graphql/mutation/ADEditAccount.mutation";
import translateErrors from "../../../../graphql/utils/translateErrors";
import {
  EditUserValue,
  EditUserVariable,
  GroupType,
  IdentityTypeType,
  JoinedTypesType,
  UserType,
} from "../../../../types/interfaces";
import getIdentityType from "../../../../utils/getIdentityType";
import { EditUserValid } from "../../../../validations/AdminValidations/AdminValidations";
import { FormAlert } from "../../../Alert/FormAlert";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import CustomTextField from "../../../TextFields/CustomTextField";

const defaultValue = (value: string | undefined) => {
  return !!value ? value : "";
};

interface UserEditCardProps {
  userData: UserType;
  groups: GroupType[];
  refetch: () => void;
}

const EditUserCard = ({ userData, groups, refetch }: UserEditCardProps) => {
  const [saveEdit, { loading }] = useMutation(editAccount);

  const initialValues: EditUserValue = {
    alert: "",
    firstName: defaultValue(userData.firstName),
    lastName: defaultValue(userData.lastName),
    username: defaultValue(userData.username),
    groupId: defaultValue(`${userData.groups[0]?.id || ""}`),
    birthDate: defaultValue(userData.birthDate),
    internalId: defaultValue(userData.internalId),
    identityType: defaultValue(userData.identityType) as keyof IdentityTypeType,
    personIdentity: defaultValue(userData.personIdentity),
    joinedEducationYear: userData.joinedEducationYear,
    joinedType: defaultValue(userData.joinedType) as keyof JoinedTypesType,
    isAdmin: userData.isStaff,
  };

  return (
    <CustomCard title="แก้ไขผู้ใช้">
      <Formik
        initialValues={initialValues}
        validationSchema={EditUserValid}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          actions.setStatus("");

          try {
            const variables: EditUserVariable = {
              accountId: userData.id,
              firstName: values.firstName,
              lastName: values.lastName,
              username: values.username,
              birthDate: values.birthDate,
              groupId: parseInt(values.groupId),
              identityType: values.identityType,
              isAdmin: !!values.isAdmin,
              joinedEducationYear: values.joinedEducationYear,
              joinedType: values.joinedType,
              personIdentity: values.personIdentity,
            };
            if (!_.isNil(values.internalId)) {
              variables["internalId"] = values.internalId;
            }
            await saveEdit({ variables });
            refetch();
            actions.setStatus("แก้ไขข้อมูลผู้ใช้สำเร็จ");
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps: FormikProps<EditUserValue>) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <FormAlert {...formikProps} />
            <Grid container spacing={2} sx={{ px: 3, py: 1 }}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="firstName"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="lastName"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="username"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="birthDate"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  name="groupId"
                  variant="outlined"
                  needNotSelected={true}
                  option={groups?.map((group) => ({
                    value: group.id,
                    label: group.name,
                  }))}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="internalId"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  name="identityType"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="personIdentity"
                  label={getIdentityType(formikProps.values.identityType)}
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="joinedEducationYear"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  name="joinedType"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formikProps.values.isAdmin}
                      onChange={formikProps.handleChange("isAdmin")}
                      name="isAdmin"
                      color="primary"
                    />
                  }
                  label="เป็นผู้ดูแลระบบ"
                />
              </Grid>
            </Grid>
            <Divider />
            <Box sx={{ px: 3, py: 2 }}>
              <LoadingButton
                variant="contained"
                sx={{ mr: 1 }}
                type="submit"
                loading={loading}
              >
                บันทึกข้อมูล
              </LoadingButton>
              <Button
                color="secondary"
                variant="outlined"
                onClick={formikProps.handleReset}
              >
                รีเซ็ต
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </CustomCard>
  );
};

export default EditUserCard;
