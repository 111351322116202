import { Chip } from "@mui/material";

import { ForgotPasswordStatusType } from "../../types/interfaces";
import getForgotPasswordStatus from "../../utils/getForgotPasswordStatus";

interface PendingPasswordResetRequestStatusChipProps {
  status: keyof ForgotPasswordStatusType;
}

const PendingPasswordRequestStatusChip = ({
  status,
}: PendingPasswordResetRequestStatusChipProps) => {
  const statusText = getForgotPasswordStatus(status);
  if (status === "PENDING") {
    return <Chip color="primary" label={statusText} />;
  }
  if (status === "REJECTED") {
    return <Chip color="error" label={statusText} />;
  }
  if (status === "APPROVED") {
    return <Chip color="success" label={statusText} />;
  }
  return <></>;
};

export default PendingPasswordRequestStatusChip;
