import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

import editGroup from "../../../../../graphql/mutation/ADEditGroup.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import {
  EditGroupValue,
  GoogleOUsType,
  GroupTypeWithPermessionAndConfig,
  MsGroupsType,
  SettingID,
} from "../../../../../types/interfaces";
import { CreateEditGroup } from "../../../../../validations/AdminValidations/AdminValidations";
import { FormAlert } from "../../../../Alert/FormAlert";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";
import CustomTextField from "../../../../TextFields/CustomTextField";

type ConfigType = {
  [key in keyof typeof SettingID]?: {
    value: string;
    id: SettingID;
  };
};

interface EditGroupDialogProps {
  data: GroupTypeWithPermessionAndConfig;
  refetch: () => void;
  MsGroups: MsGroupsType[];
  GoogleOU: GoogleOUsType[];
  isLoading: boolean;
  configs: ConfigType;
}

const EditGroupDialog = ({
  data,
  refetch,
  MsGroups,
  GoogleOU,
  isLoading,
  configs,
}: EditGroupDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <LoadingButton
          loading={isLoading}
          loadingPosition="center"
          onClick={() => setOpen(true)}
        >
          แก้ไข
        </LoadingButton>
      </Stack>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title="แก้ไขกลุ่มผู้ใช้"
      >
        {open ? (
          <EditGroupForm
            data={data}
            configs={configs}
            refetch={refetch}
            setOpen={setOpen}
            MsGroups={MsGroups}
            GoogleOU={GoogleOU}
          />
        ) : (
          <></>
        )}
      </CustomDialog>
    </>
  );
};

interface EditGroupFormProps extends Omit<EditGroupDialogProps, "isLoading"> {
  setOpen: (value: boolean) => void;
}

const EditGroupForm = ({
  data,
  refetch,
  setOpen,
  MsGroups,
  GoogleOU,
  configs,
}: EditGroupFormProps) => {
  const [edit, { loading }] = useMutation(editGroup);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: EditGroupValue = {
    alert: "",
    groupName: data.name || "",
    msGroups: data.groupConfig.MSGroupid || "",
    GoogleOU: data.groupConfig.OU || "",
    GoogleIsclassroomteacher:
      data.groupConfig.GoogleIsclassroomteacher || false,
    isStudentIdPrefixNeeded: data.groupConfig.isStudentIdPrefixNeeded || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateEditGroup}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(false);
        try {
          const variables = {
            id: data.id,
            name: values.groupName,
            MicrosoftGroup: values.msGroups,
            GoogleOU: values.GoogleOU,
            isStudentIdPrefixNeeded: values.isStudentIdPrefixNeeded,
            GoogleIsclassroomteacher: values.GoogleIsclassroomteacher,
          };
          await edit({ variables });
          refetch();
          setOpen(false);
          enqueueSnackbar("แก้ไขกลุ่มสำเร็จ", {
            variant: "success",
            transitionDuration: { appear: 500, enter: 425, exit: 195 },
          });
        } catch (error: any) {
          actions.setErrors({
            alert: translateErrors(error.graphQLErrors),
          });
        }
      }}
    >
      {(formikProps) => (
        <Box component="form" onSubmit={formikProps.handleSubmit}>
          <DialogContent sx={{ pt: 1 }}>
            <FormAlert {...formikProps} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  name="groupName"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              {configs.MICROSOFT_ENABLE?.value.toLowerCase() === "true" && (
                <Grid item xs={12}>
                  <label>
                    <Autocomplete
                      id="combo-box-demo"
                      options={MsGroups}
                      fullWidth
                      disableClearable={true}
                      getOptionLabel={(option) => option.displayName}
                      defaultValue={
                        MsGroups.filter(
                          (value) => value.id === data.groupConfig.MSGroupid
                        )[0]
                      }
                      onChange={(e, value) =>
                        formikProps.setFieldValue("msGroups", value?.id)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          label="Microsoft Group"
                          {...params}
                          size="small"
                          error={Boolean(
                            formikProps.touched.msGroups &&
                              formikProps.errors.msGroups
                          )}
                          helperText={
                            formikProps.touched.msGroups &&
                            formikProps.errors.msGroups
                          }
                        />
                      )}
                    />
                  </label>
                </Grid>
              )}
              {configs.GOOGLE_ENABLE?.value.toLowerCase() === "true" && (
                <Grid item xs={12}>
                  <label>
                    <Autocomplete
                      id="combo-box-demo"
                      options={GoogleOU}
                      fullWidth
                      disableClearable={true}
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        GoogleOU.filter(
                          (value) => value.orgUnitPath === data.groupConfig.OU
                        )[0]
                      }
                      onChange={(e, value) =>
                        formikProps.setFieldValue(
                          "GoogleOU",
                          value?.orgUnitPath
                        )
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.orgUnitPath === value.orgUnitPath
                      }
                      renderInput={(params) => (
                        <TextField
                          label="Google OUs"
                          {...params}
                          size="small"
                          error={Boolean(
                            formikProps.touched.GoogleOU &&
                              formikProps.errors.GoogleOU
                          )}
                          helperText={
                            formikProps.touched.GoogleOU &&
                            formikProps.errors.GoogleOU
                          }
                        />
                      )}
                    />
                  </label>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        formikProps.setFieldValue(
                          "isStudentIdPrefixNeeded",
                          !formikProps.values.isStudentIdPrefixNeeded
                        )
                      }
                      checked={formikProps.values.isStudentIdPrefixNeeded}
                    />
                  }
                  label="ใส่เลขรหัสนักเรียนหน้าชื่ออีเมล"
                />
              </Grid>
              {configs.GOOGLE_ENABLE?.value.toLowerCase() === "true" && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() =>
                          formikProps.setFieldValue(
                            "GoogleIsclassroomteacher",
                            !formikProps.values.GoogleIsclassroomteacher
                          )
                        }
                        checked={formikProps.values.GoogleIsclassroomteacher}
                      />
                    }
                    label="มีสิทธิ์เป็นครูใน Google Classroom"
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
            <LoadingButton loading={loading} type="submit" startIcon={<Save />}>
              บันทึก
            </LoadingButton>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
};

export default EditGroupDialog;
