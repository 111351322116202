import { gql } from "@apollo/client";

const approvePassword = gql`
  mutation ApprovePassword($id: UUID!) {
    ADApprovePasswordResetRequest(id: $id) {
      forgotQueue {
        id
      }
    }
  }
`;
export default approvePassword;
