import * as React from "react";

interface ProviderProps {
  children?: any;
}

export interface ContextValueType {
  siteTitle: string;
  setSiteTitle: React.Dispatch<React.SetStateAction<string>>;
}

const ContextDefaultValue: ContextValueType = {
  siteTitle: "",
  setSiteTitle: (state) => {},
};

export const Context = React.createContext(ContextDefaultValue);

export const ContextProvider = (props: ProviderProps) => {
  const [siteTitle, setSiteTitle] = React.useState("");

  return (
    <Context.Provider value={{ siteTitle, setSiteTitle }}>
      {props.children}
    </Context.Provider>
  );
};
