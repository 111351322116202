import {
  ADFindGroupQuery,
  ColumnsType,
  SettingID,
} from "../../../../types/interfaces";
import ListTable from "../../../ListTable/ListTable";
import EditGroupDialog from "./Dialogs/EditGroupDialog";

const columns: ColumnsType[] = [
  { id: "name", label: "ชื่อกลุ่มผู้ใช้", minWidth: 150 },
  { id: "button", label: "", minWidth: 50, align: "center" },
];

type ConfigType = {
  [key in keyof typeof SettingID]?: {
    value: string;
    id: SettingID;
  };
};

interface GroupTableProps {
  data: ADFindGroupQuery | undefined;
  refetch: () => void;
  totalItems: number | undefined;
  needPagination?: boolean;
  rowsPerPage?: number;
  pageRoute?: string;
  isLoading: boolean;
  configs: ConfigType;
}

const GroupTable = ({
  data,
  refetch,
  totalItems,
  needPagination = true,
  configs,
  rowsPerPage = 20,
  pageRoute,
  isLoading,
}: GroupTableProps) => {
  const groups = data?.ADFindGroups?.groups;

  let rows;

  if (!!groups) {
    rows = groups.map((row) => {
      return {
        id: row.id,
        name: row.name,
        button: (
          <EditGroupDialog
            data={row}
            configs={configs}
            refetch={refetch}
            MsGroups={data?.ADGetMsGroups}
            GoogleOU={data?.ADGoogleOUs}
            isLoading={isLoading}
          />
        ),
      };
    });
  }

  return (
    <ListTable
      title="กลุ่มผู้ใช้ทั้งหมด"
      columns={columns}
      rows={rows}
      refetch={refetch}
      totalItems={totalItems}
      rowsPerPage={rowsPerPage}
      needPagination={needPagination}
      pageRoute={pageRoute}
      isLoading={!data}
    />
  );
};

export default GroupTable;
