import { gql } from "@apollo/client";
import { ACCOUNT_FIELDS } from "../fragments";

const FindAction = gql`
  ${ACCOUNT_FIELDS}
  query findAction($page: Int, $filter: FindActionQueueFilter) {
    ADFindActionQueue(page: $page, filter: $filter) {
      totalPages
      totalItems
      actionQueues {
        id
        actionType
        createdAt
        lastAttemptAt
        doneAt
        updatedAt
        attempt
        status
        googleMail
        microsoftMail
        error
        account {
          ...AccountFields
        }
      }
    }
  }
`;
export default FindAction;
