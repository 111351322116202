import { Formik } from "formik";
import _ from "lodash";
import { ChangeEvent, useState } from "react";

import { ApolloError } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

import translateErrors from "../../../../graphql/utils/translateErrors";
import { UploadUserType } from "../../../../types/interfaces";
import CustomCard from "../../../CustomCard/CustomCard";
import ConfirmUploadUser from "./ConfirmUploadUser";

interface UploadUserCardProps {
  uploadFile: any;
  loading: boolean;
  data: UploadUserType[];
  error: ApolloError;
}

const UploadUserCard = ({
  uploadFile,
  loading,
  data,
  error,
}: UploadUserCardProps) => {
  const [fileName, setFileName] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;
    if (e.target.validity.valid) {
      uploadFile({ variables: { file: e.target.files[0] } }).catch((err: any) =>
        console.log(err)
      );
      setFileName(e.target.files[0].name);
    }
  };

  const getFileStateButton = () => {
    if (_.isEmpty(fileName)) return "เลือกไฟล์";
    return "เปลี่ยนไฟล์";
  };

  const getFileStateLabel = () => {
    if (loading) return "กำลังอัปโหลดไฟล์";
    if (_.isEmpty(fileName)) return "ยังไม่ได้อัปโหลดไฟล์";
    return `${fileName.slice(0, 21)}...`;
  };

  return (
    <CustomCard title="อัปโหลดผู้ใช้">
      {_.isEmpty(fileName) && !error && (
        <Alert severity="info" sx={{ mb: 2 }}>
          ไฟล์ที่สามารถอัปโหลดได้ ต้องเป็นไฟล์สกุล .xlsx เท่านั้น{" "}
          <Link href="/static/files/User Import Template.xlsx">
            ดาวน์โหลดตัวอย่างไฟล์
          </Link>
        </Alert>
      )}
      {error && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {translateErrors(error.graphQLErrors)}
        </Alert>
      )}
      <Formik
        initialValues={{
          file: "",
        }}
        onSubmit={() => {}}
      >
        {({ handleBlur }) => (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LoadingButton
                variant="outlined"
                component="label"
                sx={{ mr: 1 }}
                loading={loading}
              >
                {getFileStateButton()}
                <input
                  type="file"
                  hidden
                  accept=".xlsx"
                  onBlur={handleBlur}
                  onChange={onChange}
                  name="file"
                />
              </LoadingButton>
              <Typography color="textSecondary" variant="body2">
                {getFileStateLabel()}
              </Typography>
            </Box>
          </>
        )}
      </Formik>
      {!_.isEmpty(fileName) && !loading && (
        <ConfirmUploadUser uploadData={data} />
      )}
    </CustomCard>
  );
};

export default UploadUserCard;
