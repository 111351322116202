import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import {
  UserReadableConfig,
  UserType,
  USGetConfigsType,
} from "../../../../types/interfaces";
import PageError from "../../../PageError";

type ConfigType = {
  [key in keyof typeof UserReadableConfig]?: {
    value: string;
    id: UserReadableConfig;
  };
};

const showData = (value: string | undefined) => (!!value ? value : "-");

interface EditUserServicesListProps {
  userData: UserType;
  configData: USGetConfigsType[];
}

const EditUserServicesList = ({
  userData,
  configData,
}: EditUserServicesListProps) => {
  const configs: ConfigType = {};
  if (configData) {
    configData.map((config) =>
      Object.assign(configs, {
        [config.configKey]: { value: config.value, id: config.configKey },
      })
    );
  } else {
    return <PageError />;
  }

  const servicesList: {
    logoname: "google" | "365" | "logo";
    serviceName: string;
    value: string;
  }[] = [];

  if (configs.GOOGLE_ENABLE?.value === "True") {
    servicesList.push({
      logoname: "google",
      serviceName: "Google Apps",
      value: userData.googleMail,
    });
  }
  if (configs.MICROSOFT_ENABLE?.value === "True") {
    servicesList.push({
      logoname: "365",
      serviceName: "Office 365",
      value: userData.microsoftMail,
    });
  }
  if (configs.ISCHOOL_ENABLE?.value === "True") {
    servicesList.push({
      logoname: "logo",
      serviceName: "ISchool",
      value: userData.username,
    });
  }
  if (configs.AD_ENABLE?.value === "True") {
    servicesList.push({
      logoname: "logo",
      serviceName: "Wifi",
      value: userData.username,
    });
  }

  return (
    <>
      {servicesList.map((detail, index) => (
        <Box key={index}>
          <Typography variant="caption" color="textSecondary">
            {detail.serviceName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {showData(detail.value)}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default EditUserServicesList;
