import { useParams } from "react-router";

import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";

import EditUserCard from "../../../components/Base/AdminLayout/EditUser/EditUserCard";
import EditUserInfoCard from "../../../components/Base/AdminLayout/EditUser/EditUserInfoCard";
import PageError from "../../../components/PageError";
import PageLoading from "../../../components/PageLoading";
import Title from "../../../components/Title";
import getUser from "../../../graphql/query/ADGetUser.query";
import { ADGetUserQuery } from "../../../types/interfaces";

const EditUser = () => {
  const { id } = useParams();

  const { loading, data, refetch } = useQuery<ADGetUserQuery>(getUser, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading) return <PageLoading />;
  if (!data) return <PageError />;

  return (
    <>
      <Title title="แก้ไขผู้ใช้" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <EditUserInfoCard
            userData={data.ADGetAccount}
            configs={data.USGetConfigs}
            refetch={refetch}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <EditUserCard
            userData={data.ADGetAccount}
            groups={data.ADGetGroups.groups}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditUser;
