import { Container, Stack } from "@mui/material";

import AddUserForm from "../../../components/Base/AdminLayout/AddUser/AddUserForm";
import Title from "../../../components/Title";

const AddUser = () => {
  return (
    <>
      <Title title="เพิ่มผู้ใช้" />
      <Stack direction="row" justifyContent="flex-end">
        <Container maxWidth="sm">
          <AddUserForm />
        </Container>
      </Stack>
    </>
  );
};

export default AddUser;
