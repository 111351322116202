import { gql } from "@apollo/client";

const regisAccount = gql`
  mutation AdminCreateAccount(
    $confirmNewPassword: String!
    $newPassword: String!
    $desiredPrefix: String!
    $accountId: ID!
  ) {
    ADRequestAccount(
      confirmNewPassword: $confirmNewPassword
      newPassword: $newPassword
      desiredPrefix: $desiredPrefix
      accountId: $accountId
    ) {
      account {
        id
      }
    }
  }
`;
export default regisAccount;
