import { FormikProps, FormikValues } from "formik";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { CustomSelectList } from "./SelectList";

interface CustomSelectProps {
  name: string;
  option?: {
    value: string | number;
    label: string;
  }[];
  variant?: "outlined" | "standard" | "filled";
  needNotSelected?: boolean;
}

const CustomSelect = (
  props: CustomSelectProps &
    Omit<
      FormikProps<FormikValues>,
      "setFormikState" | "setValues" | "resetForm"
    >
) => {
  const {
    setFieldValue,
    values,
    name,
    option,
    variant,
    needNotSelected = false,
    touched,
    errors,
  } = props;

  return (
    <FormControl
      variant={variant}
      size="small"
      sx={{ minWidth: "100%" }}
      error={Boolean(
        touched[name as keyof typeof touched] &&
          errors[name as keyof typeof errors]
      )}
    >
      <InputLabel id={`${CustomSelectList[name].id}-label`}>
        {CustomSelectList[name].label}
      </InputLabel>
      <Select
        labelId={`${CustomSelectList[name].id}-label`}
        id={CustomSelectList[name].id}
        value={values[name]}
        onChange={(e) => setFieldValue(name, e.target.value)}
        label={CustomSelectList[name].label}
      >
        {needNotSelected && <MenuItem value="">ไม่เลือก</MenuItem>}
        {!!option
          ? option.map((opt, index) => (
              <MenuItem value={opt.value} key={index}>
                {opt.label}
              </MenuItem>
            ))
          : CustomSelectList[name].option.map(
              (opt, index) =>
                !!opt.value && (
                  <MenuItem value={opt.value} key={index}>
                    {opt.label}
                  </MenuItem>
                )
            )}
      </Select>
      <FormHelperText>
        {touched[name as keyof typeof touched] &&
          errors[name as keyof typeof errors]}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
