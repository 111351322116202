import { SettingID, USGetConfigsType } from "../../types/interfaces";
import { ContextValueType } from "../context";

export const getSiteTitle = (context: ContextValueType) => {
  return context.siteTitle;
};

type ConfigType = {
  [key in keyof typeof SettingID]?: {
    value: string;
    id: SettingID;
  };
};

export const setSiteTitle = (
  context: ContextValueType,
  allConfigs: USGetConfigsType[]
) => {
  const configs: ConfigType = {};
  if (allConfigs) {
    allConfigs.map((config) =>
      Object.assign(configs, {
        [config.configKey]: { value: config.value, id: config.id },
      })
    );
  }
  if (configs.ORGANIZATION_NAME?.value)
    context.setSiteTitle(configs.ORGANIZATION_NAME.value);
};
