import { gql } from "@apollo/client";
import { ACCOUNT_FIELDS } from "../fragments";

const USGetAccount = gql`
  ${ACCOUNT_FIELDS}
  query USGetAccount {
    USGetAccount {
      ...AccountFields
    }
    USGetConfigs {
      configKey
      value
    }
  }
`;

export default USGetAccount;
