import { gql } from "@apollo/client";

const rejectPassword = gql`
  mutation RejectPassword($id: UUID!) {
    ADRejectPasswordResetRequest(id: $id) {
      forgotQueue {
        id
      }
    }
  }
`;
export default rejectPassword;
