import { Formik } from "formik";

import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import translateErrors from "../../../../graphql/utils/translateErrors";
import { SettingID, SettingType } from "../../../../types/interfaces";
import { EditMicrosoftSettingValid } from "../../../../validations/AdminValidations/AdminValidations";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomTextField from "../../../TextFields/CustomTextField";

interface SettingMicrosoftProps {
  microsoftClientID?: {
    id: SettingID;
    value: string;
  };
  microsoftSecret?: {
    id: SettingID;
    value: string;
  };
  microsoftTenantID?: {
    id: SettingID;
    value: string;
  };
  emailSuffixMicrosoft?: {
    id: SettingID;
    value: string;
  };
  updateConfig: any;
  isUpdating: boolean;
}

interface initialValuesType {
  alert: string;
  microsoftClientID: string;
  microsoftSecret: string;
  microsoftTenantID: string;
  emailSuffixMicrosoft: string;
}

const SettingMicrosoft = ({
  microsoftClientID,
  microsoftSecret,
  microsoftTenantID,
  emailSuffixMicrosoft,
  updateConfig,
  isUpdating,
}: SettingMicrosoftProps) => {
  const initialValues: initialValuesType = {
    alert: "",
    microsoftClientID: microsoftClientID?.value || "",
    microsoftSecret: microsoftSecret?.value || "",
    microsoftTenantID: microsoftTenantID?.value || "",
    emailSuffixMicrosoft: emailSuffixMicrosoft?.value || "",
  };

  return (
    <CustomCard title="การตั้งค่า Microsoft">
      <Formik
        initialValues={initialValues}
        validationSchema={EditMicrosoftSettingValid}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          actions.setStatus("");
          try {
            const variables: SettingType = { configs: [] };
            if (
              !!microsoftClientID &&
              !!microsoftSecret &&
              !!microsoftTenantID &&
              !!emailSuffixMicrosoft
            ) {
              if (!(values.microsoftClientID === microsoftClientID.value)) {
                variables.configs.push({
                  configKey: "MICROSOFT_CLIENT_ID",
                  id: microsoftClientID.id,
                  value: values.microsoftClientID,
                });
              }
              if (!(values.microsoftSecret === microsoftSecret.value)) {
                variables.configs.push({
                  configKey: "MICROSOFT_SECRET",
                  id: microsoftSecret.id,
                  value: values.microsoftSecret,
                });
              }
              if (!(values.microsoftTenantID === microsoftTenantID.value)) {
                variables.configs.push({
                  configKey: "MICROSOFT_TENANT_ID",
                  id: microsoftTenantID.id,
                  value: values.microsoftTenantID,
                });
              }
              if (
                !(values.emailSuffixMicrosoft === emailSuffixMicrosoft.value)
              ) {
                variables.configs.push({
                  configKey: "EMAIL_SUFFIX_MICROSOFT",
                  id: emailSuffixMicrosoft.id,
                  value: values.emailSuffixMicrosoft,
                });
              }
            }
            updateConfig({ variables }).then(() => {
              actions.setStatus("แก้ไขการตั้งค่า Google Account สำเร็จ");
            });
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="microsoftClientID"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="microsoftSecret"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="microsoftTenantID"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="emailSuffixMicrosoft"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isUpdating}
              >
                บันทึก
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </CustomCard>
  );
};

export default SettingMicrosoft;
