import { Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Save } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
} from "@mui/material";

import regisAccount from "../../../../../graphql/mutation/AddRegisAccount.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import { RegisterAccountValue } from "../../../../../types/interfaces";
import {
  getEmailExample,
  getEmailNameTerms,
} from "../../../../../utils/getEmailTerms";
import { RegisterAccountValid } from "../../../../../validations/GlobalValidation";
import { FormAlert } from "../../../../Alert/FormAlert";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";
import CustomTextField from "../../../../TextFields/CustomTextField";

interface RegisterMailDialogProps {
  id: string;
  refetch: () => void;
  isStudentIdPrefixNeeded: boolean;
  internalId: string;
}

const RegisterMailDialog = ({
  id,
  refetch,
  isStudentIdPrefixNeeded,
  internalId,
}: RegisterMailDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>สมัครบริการ</Button>
      <CustomDialog
        title="แก้ไขชื่ออีเมล"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        {open ? (
          <RegisterMailForm
            id={id}
            refetch={refetch}
            setOpen={setOpen}
            internalId={internalId}
            isStudentIdPrefixNeeded={isStudentIdPrefixNeeded}
          />
        ) : (
          <></>
        )}
      </CustomDialog>
    </>
  );
};

interface RegisterMailFormProps {
  id: string;
  refetch: () => void;
  setOpen: (arg0: boolean) => void;
  isStudentIdPrefixNeeded: boolean;
  internalId: string;
}

const RegisterMailForm = ({
  id,
  refetch,
  setOpen,
  isStudentIdPrefixNeeded,
  internalId,
}: RegisterMailFormProps) => {
  const [register, { loading }] = useMutation(regisAccount);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: RegisterAccountValue = {
    alert: "",
    desiredPrefix: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterAccountValid}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(false);
        try {
          await register({
            variables: {
              accountId: id,
              desiredPrefix: `${
                isStudentIdPrefixNeeded ? (internalId || "00000") + "." : ""
              }${values.desiredPrefix}`,
              newPassword: values.newPassword,
              confirmNewPassword: values.confirmPassword,
            },
          });
          await refetch();
          enqueueSnackbar("สมัครบริการสำเร็จ", {
            variant: "success",
            transitionDuration: { appear: 500, enter: 425, exit: 195 },
          });
          setOpen(false);
        } catch (error: any) {
          actions.setErrors({
            alert: translateErrors(error.graphQLErrors),
          });
        }
      }}
    >
      {(formikProps: FormikProps<RegisterAccountValue>) => (
        <Box component="form" onSubmit={formikProps.handleSubmit}>
          <DialogContent sx={{ pt: 0 }}>
            <FormAlert {...formikProps} />
            {getEmailNameTerms("body2")}
            <Stack
              direction="column"
              spacing={1}
              sx={{ mt: 1, overflow: "hidden" }}
            >
              <CustomTextField
                name="desiredPrefix"
                variant="outlined"
                {...formikProps}
                inputProps={
                  isStudentIdPrefixNeeded
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            {internalId || "00000"}.
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
              />
              {getEmailExample(
                `${
                  isStudentIdPrefixNeeded ? (internalId || "00000") + "." : ""
                }${formikProps.values.desiredPrefix}`
              )}
              <CustomTextField
                name="newPassword"
                variant="outlined"
                {...formikProps}
              />
              <CustomTextField
                name="confirmPassword"
                variant="outlined"
                {...formikProps}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
            <LoadingButton loading={loading} type="submit" startIcon={<Save />}>
              บันทึก
            </LoadingButton>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
};

export default RegisterMailDialog;
