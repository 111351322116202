import { Formik, FormikProps } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack } from "@mui/material";

import addAccount from "../../../../graphql/mutation/ADAddAccount.mutation";
import translateErrors from "../../../../graphql/utils/translateErrors";
import {
  AddAccountValue,
  AddAccountVariable,
} from "../../../../types/interfaces";
import getIdentityType from "../../../../utils/getIdentityType";
import { AddAccountValid } from "../../../../validations/AdminValidations/AdminValidations";
import { FormAlert } from "../../../Alert/FormAlert";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import CustomTextField from "../../../TextFields/CustomTextField";

const AddUserForm = () => {
  const [createUser, { loading }] = useMutation(addAccount);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues: AddAccountValue = {
    alert: "",
    firstName: "",
    lastName: "",
    username: "",
    internalId: "",
    identityType: "T",
    joinedType: "",
    personIdentity: "",
    joinedEducationYear: "",
  };

  return (
    <>
      <CustomCard title="เพิ่มผู้ใช้">
        <Formik
          initialValues={initialValues}
          validationSchema={AddAccountValid}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false);
            try {
              const variables: AddAccountVariable = {
                firstName: values.firstName,
                lastName: values.lastName,
                identityType: values.identityType,
                joinedEducationYear: values.joinedEducationYear,
                joinedType: values.joinedType,
                personIdentity: values.personIdentity,
                username: values.username,
              };
              if (!!values.internalId) {
                variables["internalId"] = values.internalId;
              }
              const save = await createUser({ variables });
              if (!_.isNil(save.data)) {
                enqueueSnackbar("เพิ่มผู้ใช้สำเร็จ", {
                  variant: "success",
                  transitionDuration: {
                    appear: 500,
                    enter: 425,
                    exit: 195,
                  },
                });
                navigate(
                  `/admin/user/edit/${save.data.ADAddAccount.account.id}`
                );
              }
            } catch (error: any) {
              actions.setErrors({
                alert: translateErrors(error.graphQLErrors),
              });
            }
          }}
        >
          {(formikProps: FormikProps<AddAccountValue>) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormAlert {...formikProps} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="firstName"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="lastName"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="username"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="internalId"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    name="identityType"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="personIdentity"
                    label={getIdentityType(formikProps.values.identityType)}
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    name="joinedType"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    name="joinedEducationYear"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button onClick={() => navigate("/admin/user", { replace: true })}>กลับไปหน้าหลัก</Button>
                <LoadingButton
                  loading={loading}
                  startIcon={<PersonAdd />}
                  type="submit"
                  variant="contained"
                >
                  เพิ่มผู้ใช้
                </LoadingButton>
              </Stack>
            </Box>
          )}
        </Formik>
      </CustomCard>
    </>
  );
};

export default AddUserForm;
