import ACTION_QUEUE_STATUS from "../graphql/ActionQueueStatus";
import { ActionQueueStatusType } from "../types/interfaces";

const getActionQueueStatus = (status: keyof ActionQueueStatusType) => {
  try {
    if (ACTION_QUEUE_STATUS[status] === undefined) {
      return "-";
    }
    return ACTION_QUEUE_STATUS[status];
  } catch {
    return "-";
  }
};

export default getActionQueueStatus;
