import { FormikProps, FormikValues } from "formik";

import { Alert } from "@mui/material";

export const FormAlert = (
  props: Omit<
    FormikProps<FormikValues>,
    "setFormikState" | "setValues" | "resetForm"
  >
) => {
  const { errors, status } = props;

  return (
    <>
      {status && (
        <Alert severity="success" sx={{ my: 1 }}>
          {status}
        </Alert>
      )}
      {errors.alert && (
        <Alert severity="error" sx={{ my: 1 }}>
          {errors.alert}
        </Alert>
      )}
    </>
  );
};
