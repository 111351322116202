import { Stack } from "@mui/material";

import {
  ActionQueueType,
  ColumnsType,
  SearchActionQueueRefetchType,
} from "../../../../types/interfaces";
import { toThaiDateStringAbbr } from "../../../../utils/date";
import getActionTypeStatus from "../../../../utils/getActionTypeStatus";
import { combineName } from "../../../../utils/table";
import ActionQueueStatusChip from "../../../ActionQueueStatusChip/ActionQueueStatusChip";
import ListTable from "../../../ListTable/ListTable";
import ActionCancelDialog from "./Dialogs/ActionCancelDialog";
import ActionQueueInfoDialog from "./Dialogs/ActionQueueInfoDialog";

const columns: ColumnsType[] = [
  { id: "actionType", label: "การดำเนินการ", minWidth: 50 },
  { id: "name", label: "เจ้าของการดำเนินการ", minWidth: 100 },
  {
    id: "createdAt",
    label: "วัน-เวลาที่เพิ่ม",
    minWidth: 100,
    align: "center",
  },
  {
    id: "lastAttemptAt",
    label: "ดำเนินการครั้งล่าสุดเมื่อ",
    minWidth: 100,
    align: "center",
  },
  { id: "attempt", label: "ดำเนินการไปแล้ว", minWidth: 100, align: "center" },
  { id: "status", label: "ผล", minWidth: 100, align: "center" },
  { id: "button", label: "", minWidth: 200, align: "center" },
];

interface ActionQueueTableProps {
  actionQueues: ActionQueueType[] | undefined;
  refetch: (arg0: SearchActionQueueRefetchType) => void;
  totalItems: number | undefined;
  needPagination?: boolean;
  rowsPerPage?: number;
  pageRoute?: string;
  isLoading: boolean;
}

const ActionQueueTable = ({
  actionQueues,
  refetch,
  totalItems,
  needPagination = true,
  rowsPerPage = 20,
  pageRoute,
  isLoading,
}: ActionQueueTableProps) => {
  const renderButton = (data: ActionQueueType) => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <ActionQueueInfoDialog data={data} />
        {data.status === "PENDING" ||
          (data.status === "FAILED" && (
            <ActionCancelDialog data={data} refetch={refetch} />
          ))}
      </Stack>
    );
  };

  let rows;

  if (!!actionQueues) {
    rows = actionQueues.map((row) => {
      return {
        id: row.id,
        actionType: getActionTypeStatus(row.actionType),
        name: combineName(row.account),
        createdAt: toThaiDateStringAbbr(row.createdAt),
        lastAttemptAt: toThaiDateStringAbbr(row.lastAttemptAt),
        attempt: row.attempt === undefined ? 0 : row.attempt,
        status: <ActionQueueStatusChip status={row.status} />,
        button: renderButton(row),
      };
    });
  }

  return (
    <ListTable
      title="คิวการดำเนินการ"
      columns={columns}
      rows={rows}
      refetch={refetch}
      totalItems={totalItems}
      rowsPerPage={rowsPerPage}
      needPagination={needPagination}
      pageRoute={pageRoute}
      isLoading={isLoading}
    />
  );
};

export default ActionQueueTable;
