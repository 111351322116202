import * as Yup from "yup";

import { VALIDATION_LIST } from "./validations";

export const LoginValid = () => {
  return Yup.object().shape({
    username: VALIDATION_LIST.username,
    password: VALIDATION_LIST.password,
  });
};

export const RegisterAccountValid = () => {
  return Yup.object().shape({
    desiredPrefix: VALIDATION_LIST.desiredPrefix,
    newPassword: VALIDATION_LIST.newPassword,
    confirmPassword: VALIDATION_LIST.confirmPassword,
  });
};

export const ChangePasswordValid = () => {
  return Yup.object().shape({
    oldPassword: VALIDATION_LIST.oldPassword,
    newPassword: VALIDATION_LIST.newPassword,
    confirmPassword: VALIDATION_LIST.confirmPassword,
  });
};
