import * as React from "react";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { setSiteTitle } from "../../../dataStore/actions/SiteTitle";
import { Context } from "../../../dataStore/context";
import USGetAccount from "../../../graphql/query/USGetAccount.query";
import { UserDataComponentProps } from "../../../types/interfaces";
import PageLoading from "../../PageLoading";

const notAuthenticatedOnly = (
  Component: React.ComponentType<UserDataComponentProps>
) => {
  const NotAuthenticatedOnlyComponent = () => {
    const navigate = useNavigate();
    const { error, data } = useQuery(USGetAccount, {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    const context = React.useContext(Context);

    React.useEffect(() => {
      if (!error && data) navigate("/user", { replace: true });
      if (data) setSiteTitle(context, data.USGetConfigs);
    }, [error, data, navigate, context]);

    if (error && data) {
      return <Component data={data} />;
    }
    return <PageLoading />;
  };

  return <NotAuthenticatedOnlyComponent />;
};

export default notAuthenticatedOnly;
