import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import Title from "./Title";

const PageError = () => {
  return (
    <>
      <Title title="เกิดข้อผิดพลาด" />
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <img
          src="/static/images/error.png"
          alt="error-img"
          width="300px"
          style={{ marginBottom: 12 }}
        />
        <Typography variant="h4">มีบางอย่างผิดพลาด โปรดลองอีกครั้ง</Typography>
      </Box>
    </>
  );
};

export default PageError;
