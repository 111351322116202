import { gql } from "@apollo/client";

const suspendAccount = gql`
  mutation SuspendAccount($accountId: ID!) {
    ADSuspendAccount(accountId: $accountId) {
      account {
        id
      }
    }
  }
`;
export default suspendAccount;
