import { Stack } from "@mui/material";

import {
  ColumnsType,
  PendingPasswordRequestType,
  RefetchPageType,
} from "../../../../types/interfaces";
import { toThaiDateStringAbbr, toTime } from "../../../../utils/date";
import { combineName } from "../../../../utils/table";
import ListTable from "../../../ListTable/ListTable";
import PendingPasswordRequestStatusChip from "../../../PendingPasswordRequestStatusChip/PendingPasswordRequestStatusChip";
import PasswordApprovelDialog from "./Dialogs/ApprovePasswordDialog";
import RejectPasswordDialog from "./Dialogs/RejectPasswordDialog";

const columns: ColumnsType[] = [
  { id: "name", label: "ชื่อ-สกุล", minWidth: 150 },
  {
    id: "userType",
    label: "ประเภท",
    minWidth: 150,
    align: "center",
  },
  {
    id: "createdAt",
    label: "วัน-เวลาที่ขอ",
    minWidth: 150,
    align: "center",
  },
  { id: "status", label: "สถานะ", minWidth: 100, align: "center" },
  { id: "button", label: " ", minWidth: 50 },
];

interface ForgotPasswordQueueTableProps {
  forgotQueues: PendingPasswordRequestType[] | undefined;
  refetch: (arg0: RefetchPageType) => void;
  totalItems: number | undefined;
  needPagination?: boolean;
  rowsPerPage?: number;
  pageRoute?: string;
  isLoading: boolean;
}

const getUserType = (isStaff: boolean) => {
  return isStaff ? "บุคคลากร" : "นักเรียน";
};

const ForgotPasswordQueueTable = ({
  forgotQueues,
  refetch,
  totalItems,
  needPagination = true,
  rowsPerPage = 20,
  pageRoute,
  isLoading,
}: ForgotPasswordQueueTableProps) => {
  const renderButton = (data: PendingPasswordRequestType) =>
    data.status === "PENDING" && (
      <Stack direction="row" justifyContent="flex-end">
        <PasswordApprovelDialog data={data} refetch={refetch} />
        <RejectPasswordDialog data={data} refetch={refetch} />
      </Stack>
    );

  let rows;

  if (!!forgotQueues) {
    rows = forgotQueues.map((row) => {
      return {
        id: row.id,
        name: combineName(row.account),
        userType: getUserType(row.account.isStaff),
        createdAt: `${toThaiDateStringAbbr(row.createdAt)} เวลา ${toTime(
          row.createdAt
        )}`,
        status: <PendingPasswordRequestStatusChip status={row.status} />,
        button: renderButton(row),
      };
    });
  }

  return (
    <ListTable
      title="คำร้องขอรีเซ็ตรหัสผ่าน"
      columns={columns}
      rows={rows}
      refetch={refetch}
      totalItems={totalItems}
      rowsPerPage={rowsPerPage}
      needPagination={needPagination}
      pageRoute={pageRoute}
      isLoading={isLoading}
    />
  );
};

export default ForgotPasswordQueueTable;
