export const toThaiDateString = (dateString: string | null) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  let monthNames = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  let year = date.getFullYear();
  let month = monthNames[date.getMonth()];
  let numOfDay = date.getDate();

  return `${numOfDay} ${month} ${year}`;
};

export const toThaiDateStringAbbr = (dateString: string | null) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  let monthNames = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ษ",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];

  let year = date.getFullYear().toString();
  let month = monthNames[date.getMonth()];
  let numOfDay = date.getDate();

  return `${numOfDay} ${month} ${year}`;
};

export const toTime = (dateString: string | null) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  return `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)} น.`;
};
