import * as React from "react";
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";

import { Button, ListItem } from "@mui/material";

interface NavItemType {
  href: string;
  icon: React.ReactNode;
  title: string;
  [index: string]: any;
}

const NavItem = ({ href, icon, title, ...rest }: NavItemType) => {
  const location = useLocation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: "text.secondary",
          fontWeight: "medium",
          justifyContent: "flex-start",
          letterSpacing: 0,
          py: 1.25,
          textTransform: "none",
          width: "100%",
          ...(active && {
            color: "primary.main",
          }),
        }}
        startIcon={icon}
        to={href}
      >
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
