import { gql } from "@apollo/client";

const deleteAccount = gql`
  mutation DeleteAccount($accountId: ID!) {
    ADDeleteAccount(accountId: $accountId) {
      account {
        id
      }
    }
  }
`;
export default deleteAccount;
