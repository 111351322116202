import { Chip } from "@mui/material";
import { ActionQueueStatusType } from "../../types/interfaces";
import getActionQueueStatus from "../../utils/getActionQueueStatus";

interface ActionQueueStatusChipProps {
  status: keyof ActionQueueStatusType;
}

const ActionQueueStatusChip = ({
  status,
}: ActionQueueStatusChipProps): JSX.Element => {
  const statusText = getActionQueueStatus(status);
  if (status === "PENDING") {
    return <Chip color="primary" label={statusText} />;
  }
  if (status === "FAILED") {
    return <Chip color="error" label={statusText} />;
  }
  if (status === "DONE") {
    return <Chip color="success" label={statusText} />;
  }
  if (status === "CANCELED") {
    return <Chip label={statusText} />;
  }
  return <></>;
};

export default ActionQueueStatusChip;
