import { ColumnsType, UploadUserType } from "../../../../types/interfaces";
import { toThaiDateStringAbbr } from "../../../../utils/date";
import ListTable from "../../../ListTable/ListTable";

const columns: ColumnsType[] = [
  { id: "importType", label: "", minWidth: 50 },
  { id: "name", label: "ชื่อ-สกุล", minWidth: 100 },
  {
    id: "personIdentity",
    label: "เลขบัตรประชาชน",
    minWidth: 100,
    align: "center",
  },
  {
    id: "googleMail",
    label: "อีเมล Google",
    minWidth: 100,
    align: "center",
  },
  {
    id: "microsoftMail",
    label: "อีเมล Microsoft",
    minWidth: 100,
    align: "center",
  },
  {
    id: "joinedType",
    label: "ประเภทผู้ใช้งาน",
    minWidth: 100,
    align: "center",
  },
  {
    id: "birthDate",
    label: "วันเดือนปีเกิด",
    minWidth: 100,
    align: "center",
  },
  {
    id: "internalId",
    label: "เลขนักเรียน",
    minWidth: 100,
    align: "center",
  },
];

interface UploadUserTableProps {
  data: UploadUserType[];
  isLoading: boolean;
}

const UploadUserTable = ({ data, isLoading }: UploadUserTableProps) => {
  const importTypePriority: {
    NEW: number;
    UPDATE: number;
  } = { NEW: 0, UPDATE: 1 };

  let rows: {
    [index: string]: string | number;
    importType: keyof typeof importTypePriority;
  }[] = [];

  if (data) {
    rows = data.map((row, index) => {
      return {
        id: index,
        importType: row.importType,
        name: `${row.account.firstName} ${row.account.lastName}`,
        personIdentity: row.account.personIdentity,
        googleMail: row.account.googleMail || "-",
        microsoftMail: row.account.microsoftMail || "-",
        joinedType: row.account.joinedType,
        birthDate: toThaiDateStringAbbr(row.account.birthDate),
        internalId: row.account.internalId,
      };
    });

    rows.sort(
      (first, second) =>
        importTypePriority[second.importType] -
        importTypePriority[first.importType]
    );
  }

  return (
    <ListTable
      title="ตรวจสอบข้อมูล"
      columns={columns}
      rows={rows}
      rowsPerPage={5}
      totalItems={rows.length}
      isLoading={isLoading}
    />
  );
};

export default UploadUserTable;
