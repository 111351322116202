import { gql } from "@apollo/client";

import { ACCOUNT_FIELDS } from "../fragments";

const findUser = gql`
  ${ACCOUNT_FIELDS}
  query FindUser($page: Int, $filter: AccountsSearchInput) {
    ADGetAccounts(page: $page, filter: $filter) {
      totalPages
      totalItems
      accounts {
        ...AccountFields
      }
    }
    ADGetGroups {
      groups {
        id
        name
      }
    }
  }
`;
export default findUser;
