import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Typography } from "@mui/material";

// interface MUIEditorProps {
//   label?: string;
//   defualtValue?: string;
//   name: string;
// }

// props: MUIEditorProps &
//   Omit<
//     FormikProps<FormikValues>,
//     "setFormikState" | "setValues" | "resetForm"
//   >
const MUIEditor = (props) => {
  return (
    <>
      <Typography varaint="caption" color="textSecondary">
        {props.label}
      </Typography>
      <CKEditor
        initData={<p>Hello from CKEditor 4!</p>}
        config={{
          toolbar: [
            "bold",
            "italic",
            "link",
            "undo",
            "redo",
            "numberedList",
            "bulletedList",
          ],
        }}
        data={props.values[props.name]}
        editor={ClassicEditor}
        onBlur={(event, editor) => {
          const data = editor.getData();
          props.setFieldValue(props.name, data);
        }}
      />
    </>
  );
};

export default MUIEditor;
