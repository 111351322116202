import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";

import ForgotPasswordQueueTable from "../../../components/Base/AdminLayout/ForgotPasswordQueue/ForgotPasswordQueueTable";
import SearchForgotPasswordQueue from "../../../components/Base/AdminLayout/ForgotPasswordQueue/SearchForgotPasswordQueue";
import PageError from "../../../components/PageError";
import Title from "../../../components/Title";
import FindForgot from "../../../graphql/query/ADFindForgot.query";
import { ADFindForgotPasswordQuery } from "../../../types/interfaces";

const ForgotPasswordQueue = () => {
  const { loading, data, refetch } = useQuery<ADFindForgotPasswordQuery>(
    FindForgot,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (!loading && !data) return <PageError />;

  return (
    <>
      <Title title="คำร้องขอรีเซ็ตรหัสผ่าน" />
      <Grid container spacing={3}>
        <SearchForgotPasswordQueue loading={loading} refetch={refetch} />
        <Grid item xs={12}>
          <ForgotPasswordQueueTable
            isLoading={loading}
            forgotQueues={data?.ADFindForgotQueue.forgotQueues}
            refetch={refetch}
            totalItems={data?.ADFindForgotQueue.totalItems}
            rowsPerPage={20}
            pageRoute="/admin/actionqueue"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordQueue;
