import { ActionTypeStatusType } from "../types/interfaces";

const ACTION_TYPE_STATUS: ActionTypeStatusType = {
  CREATE_MS: "สร้าง Microsoft Account",
  CREATE_GOOGLE: "สร้าง Google Account",
  UPDATE_MAIL_GOOGLE: "อัปเดตอีเมล Google Account",
  UPDATE_MAIL_MS: "อัปเดตอีเมล Microsoft Account",
  UPDATE_PASSWORD_GOOGLE: "เปลี่ยนรหัส Google Account",
  UPDATE_PASSWORD_MS: "เปลี่ยนรหัส Microsoft Account",
  EDIT_GOOGLE: "แก้ไข Google Account",
  EDIT_MS: "แก้ไข Microsoft Account",
  DELETE_MS: "ลบ Microsoft Account",
  DELETE_GOOGLE: "ลบ Google Account",
  CREATE_AD: "สร้าง AD Account",
  UPDATE_MAIL_AD: "อัปเดตอีเมล AD Account",
  UPDATE_PASSWORD_AD: "เปลี่ยนรหัส AD Account",
  SUSPEND_AD: "ระงับ AD Account",
  UNSUSPEND_AD: "ยกเลิกการระงับ AD Account",
  DELETE_AD: "ลบ AD Account",
  EDIT_AD: "แก้ไข AD Account",

  UNKNOWN: "การกระทำที่ไม่ทราบ",
};

export default ACTION_TYPE_STATUS;
