import { ActionQueueStatusType } from "../types/interfaces";

const ACTION_QUEUE_STATUS: ActionQueueStatusType = {
  PENDING: "รอดำเนินการ",
  DONE: "สำเร็จ",
  FAILED: "ล้มเหลว",
  CANCELED: "ยกเลิกแล้ว",
};

export default ACTION_QUEUE_STATUS;
