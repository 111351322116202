import { gql } from "@apollo/client";

const addGroup = gql`
  mutation addGroup(
    $GoogleOU: String!
    $MicrosoftGroup: String!
    $name: String!
    $isStudentIdPrefixNeeded: Boolean!
    $GoogleIsclassroomteacher: Boolean!
  ) {
    ADAddGroup(
      GoogleOU: $GoogleOU
      MicrosoftGroup: $MicrosoftGroup
      name: $name
      isStudentIdPrefixNeeded: $isStudentIdPrefixNeeded
      GoogleIsclassroomteacher: $GoogleIsclassroomteacher
    ) {
      group {
        id
        name
      }
    }
  }
`;
export default addGroup;
