import { IdentityTypeType } from "../types/interfaces";

const IDENTITY_TYPE: IdentityTypeType = {
  T: "บัตรประชาชน",
  P: "หนังสือเดินทาง",
  A: "ต่างด้าว",
  O: "อื่น ๆ",
};

export default IDENTITY_TYPE;
