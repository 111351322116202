import { gql } from "@apollo/client";

const editMail = gql`
  mutation EditMail($accountId: ID!, $desiredPrefix: String!) {
    ADEditAccountMail(accountId: $accountId, desiredPrefix: $desiredPrefix) {
      account {
        id
      }
    }
  }
`;
export default editMail;
