import { Formik } from "formik";

import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import translateErrors from "../../../../graphql/utils/translateErrors";
import { SettingID, SettingType } from "../../../../types/interfaces";
import { EditSiteSettingValid } from "../../../../validations/AdminValidations/AdminValidations";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import CustomTextField from "../../../TextFields/CustomTextField";
import MUIEditor from "./MUIEditor/MUIEditor";
import { useState } from "react";

interface SettingSiteProps {
  organizationName?: {
    id: SettingID;
    value: string;
  };
  sidebarGuide?: {
    id: SettingID;
    value: string;
  };
  isGoogleEnabled?: {
    id: SettingID;
    value: string;
  };
  isMicrosoftEnabled?: {
    id: SettingID;
    value: string;
  };
  isIschoolEnabled?: {
    id: SettingID;
    value: string;
  };
  isActiveDirectoryEnabled?: {
    id: SettingID;
    value: string;
  };
  updateConfig: any;
  isUpdating: boolean;
}

interface initialValuesType {
  alert: string;
  organizationName: string;
  sidebarGuide: string;
  isGoogleEnabled: boolean;
  isMicrosoftEnabled: boolean;
  isIschoolEnabled: boolean;
  isActiveDirectoryEnabled: boolean;
}

const convertJSBoolToPythonBool = (value: boolean) => {
  return value ? "True" : "False";
};

const SettingSite = ({
  organizationName,
  sidebarGuide,
  isGoogleEnabled,
  isMicrosoftEnabled,
  isIschoolEnabled,
  isActiveDirectoryEnabled,
  updateConfig,
  isUpdating,
}: SettingSiteProps) => {
  const [clickCount, setClickCount] = useState(0);
  const initialValues: initialValuesType = {
    alert: "",
    organizationName: organizationName?.value || "",
    sidebarGuide: sidebarGuide?.value || "",
    isGoogleEnabled: isGoogleEnabled?.value.toLocaleLowerCase() === "true",
    isMicrosoftEnabled:
      isMicrosoftEnabled?.value.toLocaleLowerCase() === "true",
    isIschoolEnabled: isIschoolEnabled?.value.toLocaleLowerCase() === "true",
    isActiveDirectoryEnabled:
      isActiveDirectoryEnabled?.value.toLocaleLowerCase() === "true",
  };

  return (
    <CustomCard title="การตั้งค่าเว็บไซต์">
      <Formik
        initialValues={initialValues}
        validationSchema={EditSiteSettingValid}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          actions.setStatus("");
          try {
            const variables: SettingType = { configs: [] };
            if (
              !!organizationName &&
              !!sidebarGuide &&
              !!isGoogleEnabled &&
              !!isMicrosoftEnabled &&
              !!isIschoolEnabled &&
              !!isActiveDirectoryEnabled
            ) {
              if (values.organizationName !== organizationName?.value) {
                variables.configs.push({
                  configKey: "ORGANIZATION_NAME",
                  id: organizationName.id,
                  value: values.organizationName,
                });
              }
              if (values.sidebarGuide !== sidebarGuide?.value) {
                variables.configs.push({
                  configKey: "SIDEBAR_GUIDE",
                  id: sidebarGuide.id,
                  value: values.sidebarGuide,
                });
              }
              if (
                convertJSBoolToPythonBool(values.isGoogleEnabled) !==
                isGoogleEnabled?.value
              ) {
                variables.configs.push({
                  configKey: "GOOGLE_ENABLE",
                  id: isGoogleEnabled.id,
                  value: convertJSBoolToPythonBool(values.isGoogleEnabled),
                });
              }
              if (
                convertJSBoolToPythonBool(values.isMicrosoftEnabled) !==
                isMicrosoftEnabled?.value
              ) {
                variables.configs.push({
                  configKey: "MICROSOFT_ENABLE",
                  id: isMicrosoftEnabled.id,
                  value: convertJSBoolToPythonBool(values.isMicrosoftEnabled),
                });
              }
              if (
                convertJSBoolToPythonBool(values.isIschoolEnabled) !==
                isIschoolEnabled?.value
              ) {
                variables.configs.push({
                  configKey: "ISCHOOL_ENABLE",
                  id: isIschoolEnabled.id,
                  value: convertJSBoolToPythonBool(values.isIschoolEnabled),
                });
              }
              if (
                convertJSBoolToPythonBool(values.isActiveDirectoryEnabled) !==
                isActiveDirectoryEnabled?.value
              ) {
                variables.configs.push({
                  configKey: "AD_ENABLE",
                  id: isActiveDirectoryEnabled.id,
                  value: convertJSBoolToPythonBool(
                    values.isActiveDirectoryEnabled
                  ),
                });
              }
            }
            updateConfig({ variables }).then(() => {
              actions.setStatus("แก้ไขการตั้งค่า Google Account สำเร็จ");
            });
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <Typography
              color="textSecondary"
              variant="body2"
              onClick={() => setClickCount(clickCount + 1)}
              sx={{ pb: 2 }}
            >
              การตั้งค่าในส่วนการแสดงผลเว็บไซต์
            </Typography>
            <Grid container spacing={3}>
              {clickCount > 10 && (
                <>
                  <Grid item xs={12} md={6}>
                    <CustomCheckbox name="isGoogleEnabled" {...formikProps} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomCheckbox
                      name="isMicrosoftEnabled"
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomCheckbox name="isIschoolEnabled" {...formikProps} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomCheckbox
                      name="isActiveDirectoryEnabled"
                      {...formikProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="organizationName"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12}>
                <MUIEditor
                  label="แก้ไขวิธีการใช้งานในหน้าเข้าสู่ระบบ"
                  name="sidebarGuide"
                  {...formikProps}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isUpdating}
              >
                บันทึก
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </CustomCard>
  );
};

export default SettingSite;
