import * as Yup from "yup";

import { VALIDATION_LIST } from "../validations";

export const AddAccountValid = () => {
  return Yup.object().shape({
    firstName: VALIDATION_LIST.firstName,
    lastName: VALIDATION_LIST.lastName,
    username: VALIDATION_LIST.username,
    identityType: VALIDATION_LIST.identityType,
    personIdentity: VALIDATION_LIST.personIdentity,
    joinedType: VALIDATION_LIST.joinedType,
    joinedEducationYear: VALIDATION_LIST.joinedEducationYear,
  });
};

export const ChangeMailValid = () => {
  return Yup.object().shape({
    desiredPrefix: VALIDATION_LIST.desiredPrefix,
  });
};

export const ResetPasswordValid = () => {
  return Yup.object().shape({
    newPassword: VALIDATION_LIST.newPassword,
    confirmPassword: VALIDATION_LIST.confirmPassword,
  });
};

export const EditUserValid = () => {
  return Yup.object().shape({
    firstName: VALIDATION_LIST.firstName,
    lastName: VALIDATION_LIST.lastName,
    username: VALIDATION_LIST.username,
    groupId: VALIDATION_LIST.groupId,
    birthDate: VALIDATION_LIST.birthDate,
    identityType: VALIDATION_LIST.identityType,
    personIdentity: VALIDATION_LIST.personIdentity,
    joinedEducationYear: VALIDATION_LIST.joinedEducationYear,
    joinedType: VALIDATION_LIST.joinedType,
  });
};

export const CreateEditGroup = () => {
  return Yup.object().shape({
    groupName: Yup.string().max(255).required("โปรดกรอกชื่อกลุ่ม"),
    // msGroups: Yup.string().required("โปรดเลือก Microsoft Group"),
    // GoogleOU: Yup.string().required("โปรดเลือก Google OUs"),
  });
};

export const EditGoogleSettingValid = () => {
  return Yup.object().shape({
    googleAdminAccountMail: Yup.string()
      .max(255)
      .required("โปรดกรอกช่องนี้")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "อีเมลไม่ถูกต้อง"
      ),
    googleCustomerID: Yup.string().required("โปรดกรอกช่องนี้"),
    // googleAPIClientOAuthID: Yup.string().required("โปรดกรอกช่องนี้"),
    emailSuffixGoogle: Yup.string().required("โปรดกรอกช่องนี้"),
  });
};

export const EditMicrosoftSettingValid = () => {
  return Yup.object().shape({
    microsoftClientID: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
    microsoftSecret: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
    microsoftTenantID: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
    emailSuffixMicrosoft: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
  });
};

export const EditRecaptchaSettingValid = () => {
  return Yup.object().shape({
    recaptchaSecret: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
    recaptchaSiteKey: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
  });
};

export const EditSiteSettingValid = () => {
  return Yup.object().shape({
    organizationName: Yup.string().max(255).required("โปรดกรอกช่องนี้"),
  });
};
