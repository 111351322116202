import * as React from "react";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { setSiteTitle } from "../../../dataStore/actions/SiteTitle";
import { Context } from "../../../dataStore/context";
import USGetAccount from "../../../graphql/query/USGetAccount.query";
import PageLoading from "../../PageLoading";

const notAuthenticatedOnly = (Component: React.ComponentType) => {
  const NotAuthenticatedOnlyComponent = () => {
    const navigate = useNavigate();
    const { error, data } = useQuery(USGetAccount, {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    const context = React.useContext(Context);

    React.useEffect(() => {
      if (data) setSiteTitle(context, data.USGetConfigs);
      if (data?.USGetAccount) {
        if (data.USGetAccount.isStaff)
          navigate("/admin/dashboard", { replace: true });
        else navigate("/user", { replace: true });
      }
    }, [data, navigate, context]);

    if (error) {
      return <Component />;
    }
    return <PageLoading />;
  };

  return <NotAuthenticatedOnlyComponent />;
};

export default notAuthenticatedOnly;
