import { gql } from "@apollo/client";
import { ACCOUNT_FIELDS } from "../fragments";

const FindForgot = gql`
  ${ACCOUNT_FIELDS}
  query findForgot($page: Int, $filter: FindForgotQueueResponseFilter) {
    ADFindForgotQueue(page: $page, filter: $filter) {
      totalPages
      totalItems
      forgotQueues {
        account {
          ...AccountFields
        }
        id
        status
        createdAt
      }
    }
  }
`;
export default FindForgot;
