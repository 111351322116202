import { Formik } from "formik";

import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import translateErrors from "../../../../graphql/utils/translateErrors";
import { SettingID, SettingType } from "../../../../types/interfaces";
import { EditGoogleSettingValid } from "../../../../validations/AdminValidations/AdminValidations";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomTextField from "../../../TextFields/CustomTextField";
import CustomSelect from "../../../CustomSelect/CustomSelect";

interface SettingGoogleProps {
  googleAdminAccountMail?: {
    id: SettingID;
    value: string;
  };
  googleCustomerID?: {
    id: SettingID;
    value: string;
  };
  googleAPIClientOAuthID?: {
    id: SettingID;
    value: string;
  };
  emailSuffixGoogle?: {
    id: SettingID;
    value: string;
  };
  googleClassroomTeacherGroup?: {
    id: SettingID;
    value: string;
  };
  googleGroups?: {
    id: string;
    name: string;
  }[];
  updateConfig: any;
  isUpdating: boolean;
}

interface initialValuesType {
  alert: string;
  googleAdminAccountMail: string;
  googleCustomerID: string;
  googleAPIClientOAuthID: string;
  emailSuffixGoogle: string;
  googleClassroomTeacherGroup: string;
}

const SettingGoogle = ({
  googleAdminAccountMail,
  updateConfig,
  googleCustomerID,
  googleAPIClientOAuthID,
  emailSuffixGoogle,
  googleClassroomTeacherGroup,
  googleGroups,
  isUpdating,
}: SettingGoogleProps) => {
  const initialValues: initialValuesType = {
    alert: "",
    googleAdminAccountMail: googleAdminAccountMail?.value || "",
    googleCustomerID: googleCustomerID?.value || "",
    googleAPIClientOAuthID: googleAPIClientOAuthID?.value || "",
    emailSuffixGoogle: emailSuffixGoogle?.value || "",
    googleClassroomTeacherGroup: googleClassroomTeacherGroup?.value || "",
  };

  return (
    <CustomCard title="การตั้งค่า Google Account">
      <Formik
        initialValues={initialValues}
        validationSchema={EditGoogleSettingValid}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          actions.setStatus("");
          try {
            const variables: SettingType = { configs: [] };
            if (
              !!googleCustomerID &&
              !!googleAdminAccountMail &&
              !!googleAPIClientOAuthID &&
              !!emailSuffixGoogle &&
              !!googleClassroomTeacherGroup
            ) {
              if (
                values.googleAdminAccountMail !== googleAdminAccountMail?.value
              ) {
                variables.configs.push({
                  configKey: "GOOGLE_ADMIN_ACCOUNT_MAIL",
                  id: googleAdminAccountMail.id,
                  value: values.googleAdminAccountMail,
                });
              }
              if (values.googleCustomerID !== googleCustomerID?.value) {
                variables.configs.push({
                  configKey: "GOOGLE_CUSTOMER_ID",
                  id: googleCustomerID.id,
                  value: values.googleCustomerID,
                });
              }
              if (
                values.googleAPIClientOAuthID !== googleAPIClientOAuthID?.value
              ) {
                variables.configs.push({
                  configKey: "GOOGLE_API_CLIENT_OAUTH_SECRET",
                  id: googleAPIClientOAuthID.id,
                  value: values.googleAPIClientOAuthID,
                });
              }
              if (values.emailSuffixGoogle !== emailSuffixGoogle?.value) {
                variables.configs.push({
                  configKey: "EMAIL_SUFFIX_GOOGLE",
                  id: emailSuffixGoogle.id,
                  value: values.emailSuffixGoogle,
                });
              }
              if (
                values.googleClassroomTeacherGroup !==
                googleClassroomTeacherGroup?.value
              ) {
                variables.configs.push({
                  configKey: "GOOGLE_CLASSROOM_TEACHER_GROUP",
                  id: googleClassroomTeacherGroup.id,
                  value: values.googleClassroomTeacherGroup,
                });
              }
            }
            updateConfig({ variables }).then(() => {
              actions.setStatus("แก้ไขการตั้งค่า Google Account สำเร็จ");
            });
          } catch (error: any) {
            actions.setErrors({
              alert: translateErrors(error.graphQLErrors),
            });
          }
        }}
      >
        {(formikProps) => (
          <Box component="form" onSubmit={formikProps.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="googleAdminAccountMail"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="googleCustomerID"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="googleAPIClientOAuthID"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="emailSuffixGoogle"
                  variant="outlined"
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  name="googleClassroomTeacherGroup"
                  variant="outlined"
                  option={googleGroups?.map((group) => ({
                    value: group.id,
                    label: group.name,
                  }))}
                  {...formikProps}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isUpdating}
              >
                บันทึก
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Formik>
    </CustomCard>
  );
};

export default SettingGoogle;
