import { gql } from "@apollo/client";

import { ACCOUNT_FIELDS } from "../fragments";

const getUser = gql`
  ${ACCOUNT_FIELDS}
  query GetUser($id: ID!) {
    ADGetAccount(id: $id) {
      ...AccountFields
    }
    ADGetGroups {
      groups {
        id
        name
      }
    }
    USGetConfigs {
      configKey
      value
    }
  }
`;
export default getUser;
