import { MouseEvent, useContext, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  AccountCircle,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { getSiteTitle } from "../dataStore/actions/SiteTitle";
import { Context } from "../dataStore/context";
import logoutUser from "../graphql/mutation/Logout.mutation";
import Logo from "./Logo";

interface AdminNavbarProps {
  onMobileNavOpen?: () => void;
  [index: string]: any;
}

interface NavbarProps {
  isAdmin?: boolean;
}

const Navbar = ({
  isAdmin,
  onMobileNavOpen,
  ...props
}: NavbarProps & AdminNavbarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logout] = useMutation(logoutUser);

  const context = useContext(Context);
  const getTitle = getSiteTitle(context);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.reload();
    } catch (err: any) {
      console.log(err);
    }
  };

  const menuId = "account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        ออกจากระบบ
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        sx={{
          borderBottom: "1px solid #dadce0",
          boxShadow: "15px 15px 30px #f2f2f2, -15px -15px 30px #ffffff;",
        }}
      >
        <Toolbar>
          <Logo height="40px" />
          <Typography
            color="white"
            variant="h6"
            sx={{
              ml: 2,
            }}
          >
            {getTitle}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex" }}>
            <Button
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              variant="outlined"
              sx={{
                minWidth: 0,
                width: 40,
                height: 40,
                borderRadius: 3,
                borderColor: "#ffffff24",
                "&:hover": {
                  borderColor: "#ffffff41",
                },
              }}
            >
              <AccountCircle sx={{ color: "white" }} />
            </Button>
          </Box>
          {isAdmin && (
            <Hidden lgUp>
              <IconButton
                sx={{
                  minWidth: 0,
                  width: 40,
                  height: 40,
                  borderRadius: 3,
                  ml: 1,
                  border: "1px solid #ffffff24",
                  "&:hover": {
                    borderColor: "#ffffff41",
                  },
                }}
                color="inherit"
                onClick={onMobileNavOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
};

export default Navbar;
