import { GraphQLError } from "graphql";
import _ from "lodash";

import ERRORS from "../Errors";

const translateErrors = (graphQLErrors: readonly GraphQLError[]) => {
  const errorArray = [];
  for (const graphQLError of graphQLErrors) {
    try {
      const errorCode = graphQLError.extensions?.ERROR_CODE;
      if (ERRORS[errorCode] === undefined) {
        return "เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ";
      }
      errorArray.push(ERRORS[errorCode]);
    } catch {
      return "เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ";
    }
  }
  if (_.isEmpty(errorArray)) return "เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ";
  return errorArray.join(", ");
};

export default translateErrors;
