import { Formik, FormikProps } from "formik";
import _ from "lodash";

import { RotateLeft, Search } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

import {
  SearchForgotPasswordQueueFilterType,
  SearchForgotPasswordQueueValue,
  SearchPasswordQueueRefetchType,
} from "../../../../types/interfaces";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import CustomTextField from "../../../TextFields/CustomTextField";

interface SearchActionQueueProps {
  loading: boolean;
  refetch: (arg0: SearchPasswordQueueRefetchType) => void;
}

const SearchForgotPasswordQueue = ({
  loading,
  refetch,
}: SearchActionQueueProps) => {
  const initialValues: SearchForgotPasswordQueueValue = {
    alert: "",
    personIdentity: "",
    createdAt: "",
    forgotStatus: "",
  };

  return (
    <Grid item xs={12}>
      <CustomCard title="ค้นหาคำร้อง">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false);
            try {
              const filter: SearchForgotPasswordQueueFilterType = {};
              if (!_.isEmpty(values.personIdentity)) {
                filter["account"] = {};
                filter["account"]["personIdentity"] = values.personIdentity;
              }
              if (!_.isEmpty(values.createdAt)) {
                filter["createdAt"] = new Date(values.createdAt)
                  .toISOString()
                  .slice(0, 10);
              }
              if (!_.isEmpty(values.forgotStatus)) {
                filter["status"] = values.forgotStatus;
              }
              await refetch({ filter, page: 1 });
            } catch (error) {}
          }}
        >
          {(formikProps: FormikProps<SearchForgotPasswordQueueValue>) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                  <CustomTextField
                    name="personIdentity"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <CustomTextField
                    name="createdAt"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <CustomSelect
                    name="forgotStatus"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "end" }}>
                    <LoadingButton
                      color="primary"
                      type="submit"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      startIcon={<Search />}
                      loading={loading}
                    >
                      ค้นหา
                    </LoadingButton>
                    <Button
                      type="reset"
                      variant="text"
                      startIcon={<RotateLeft />}
                      onClick={() => {
                        formikProps.handleReset();
                        refetch({ filter: {}, page: 1 });
                      }}
                    >
                      รีเซต
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </CustomCard>
    </Grid>
  );
};

export default SearchForgotPasswordQueue;
