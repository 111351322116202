import { colors, createTheme } from "@mui/material";

import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: "#f5f5f5",
      paper: colors.common.white,
    },
    primary: {
      contrastText: "#ffffff",
      main: "#0382cd",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
  },
  typography,
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
      },
      editor: {
        borderTop: "1px solid rgba(0, 0, 0, 0.23)",
        padding: "4px 16px",
      },
    },
  },
});

export default theme;
