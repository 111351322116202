import { useQuery } from "@apollo/client";
import { HourglassTopOutlined, Person } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { cyan, green, indigo, red } from "@mui/material/colors";

import ActionQueueTable from "../../../components/Base/AdminLayout/ActionQueue/ActionQueueTable";
import DashboardSummary from "../../../components/Base/AdminLayout/Dashboard/DashboardSummary";
import ForgotPasswordQueueTable from "../../../components/Base/AdminLayout/ForgotPasswordQueue/ForgotPasswordQueueTable";
import PageError from "../../../components/PageError";
import Title from "../../../components/Title";
import ADDashboard from "../../../graphql/query/ADGetDashboard.query";
import { AdminDashboardQuery } from "../../../types/interfaces";

const AdminDashboard = () => {
  const { loading, data, refetch } = useQuery<AdminDashboardQuery>(
    ADDashboard,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (!loading && !data) return <PageError />;

  return (
    <>
      <Title title="ภาพรวม" />
      <Grid container spacing={3}>
        <DashboardSummary
          title="จำนวนบัญชีทั้งหมด"
          amount={data?.ADGetDashboard.totalUser}
          classifier="บัญชี"
          iconColor={red[600]}
          icon={<Person />}
        />
        <DashboardSummary
          title="คิวการดำเนินการวันนี้"
          amount={data?.ADGetDashboard.actionQueueToday}
          classifier="คิว"
          iconColor={green[600]}
          icon={<Person />}
        />
        <DashboardSummary
          title="คิวดำเนินการที่เหลือ"
          amount={data?.ADGetDashboard.actionQueueLeft}
          classifier="คิว"
          iconColor={cyan[600]}
          icon={<Person />}
        />
        <DashboardSummary
          title="คำร้องขอรีเซ็ตรหัสผ่าน"
          amount={data?.ADGetDashboard.passwordRequest}
          classifier="บัญชี"
          iconColor={indigo[600]}
          icon={<HourglassTopOutlined />}
        />
        <Grid item xs={12}>
          <ActionQueueTable
            isLoading={loading}
            actionQueues={data?.ADGetWaitingActionQueue.actionQueues}
            refetch={refetch}
            totalItems={data?.ADGetWaitingActionQueue.totalItems}
            needPagination={false}
            rowsPerPage={10}
            pageRoute="/admin/actionqueue"
          />
        </Grid>
        <Grid item xs={12}>
          <ForgotPasswordQueueTable
            isLoading={loading}
            forgotQueues={data?.ADGetPendingPasswordResetRequest.forgotQueues}
            refetch={refetch}
            totalItems={data?.ADGetWaitingActionQueue.totalItems}
            needPagination={false}
            rowsPerPage={10}
            pageRoute="/admin/forgotqueue"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminDashboard;
