import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Block } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import deleteAccount from "../../../../../graphql/mutation/ADDeleteAccount.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import { UserType } from "../../../../../types/interfaces";
import { combineName } from "../../../../../utils/table";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";
import { useNavigate } from "react-router-dom";

interface DeleteUserDialogProps {
  data: UserType;
}

const DeleteUserDialog = ({ data }: DeleteUserDialogProps) => {
  const [open, setOpen] = useState(false);
  const [suspend, { loading }] = useMutation(deleteAccount);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancel = async () => {
    try {
      await suspend({ variables: { accountId: data.id } });
      handleClose();
      enqueueSnackbar("ลบบัญชีสำเร็จ", {
        variant: "success",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
      navigate("/admin/user", { replace: true });
    } catch (e: any) {
      handleClose();
      enqueueSnackbar(translateErrors(e.graphQLErrors), {
        variant: "error",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>ลบบัญชี</Button>
      <CustomDialog
        title="คุณต้องการลบบัญชีหรือไม่"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        <DialogContent sx={{ pt: 0 }}>
          <DialogContentText>
            คุณต้องการลบบัญชีของ {combineName(data)} หรือไม่
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
          <LoadingButton
            onClick={handleCancel}
            startIcon={<Block />}
            loading={loading}
            color="error"
          >
            ลบ
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default DeleteUserDialog;
