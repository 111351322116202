import { Link as RouterLink } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { PersonAdd, UploadOutlined } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";

import SearchUser from "../../../components/Base/AdminLayout/Users/SearchUser";
import UserTable from "../../../components/Base/AdminLayout/Users/UserTable";
import PageError from "../../../components/PageError";
import Title from "../../../components/Title";
import findUser from "../../../graphql/query/ADFindUser.query";
import { ADFindUserQuery } from "../../../types/interfaces";

const Users = () => {
  const { loading, data, refetch } = useQuery<ADFindUserQuery>(findUser, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  if (!loading && !data) return <PageError />;

  return (
    <>
      <Title title="ผู้ใช้ทั้งหมด" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={3}>
            <Button
              to={`/admin/user/upload`}
              component={RouterLink}
              startIcon={<UploadOutlined />}
            >
              อัปโหลดผู้ใช้
            </Button>
            <Button
              variant="contained"
              to={`/admin/user/create`}
              component={RouterLink}
              startIcon={<PersonAdd />}
            >
              เพิ่มผู้ใช้
            </Button>
          </Stack>
        </Grid>
        <SearchUser
          loading={loading}
          refetch={refetch}
          groups={data?.ADGetGroups.groups}
        />
        <Grid item xs={12}>
          <UserTable
            isLoading={loading}
            userData={data?.ADGetAccounts?.accounts}
            refetch={refetch}
            totalItems={data?.ADGetAccounts?.totalItems}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Users;
