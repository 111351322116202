import _ from "lodash";
import { useEffect, useState } from "react";

import { ApolloError, useMutation } from "@apollo/client";
import { Alert, Box, Button } from "@mui/material";

import importUsers from "../../../../graphql/mutation/ADImportAccount.mutation";
import { UploadUserType } from "../../../../types/interfaces";
import UploadSuccessFailModal from "./UploadSuccessFailModal";

interface ConfirmUploadUserProps {
  uploadData: UploadUserType[];
}

const ConfirmUploadUser = ({ uploadData }: ConfirmUploadUserProps) => {
  const [uploadUser, { data, loading, error }] = useMutation(importUsers);
  const [isDone, setDone] = useState(false);
  const [status, setStatus] = useState("success");

  useEffect(() => {
    if (!_.isUndefined(data) && !error) {
      setDone(true);
      setStatus("success");
    } else if (error) {
      setDone(true);
      setStatus("error");
    }
  }, [data, loading, error, setDone]);

  return (
    <>
      <Alert severity="warning" sx={{ mt: 2 }}>
        โปรดตรวจสอบความถูกต้องของข้อมูลในตารางและด้านล่างก่อนจะทำการดำเนินการ
        เนื่องจากไม่สามารถย้อนกลับได้
        <br />
        <br />
        ข้อมูลผู้ใช้ที่ต้องการอัปโหลดทั้ง:{" "}
        {!_.isNil(uploadData) && uploadData.length} ผู้ใช้ <br />
        ข้อมูลผู้ใช้ที่ต้องการจะเขียนทับจำนวน:{" "}
        {!_.isNil(uploadData) &&
          uploadData.filter((row) => row.importType === "UPDATE").length}{" "}
        ผู้ใช้
        <br />
        ข้อมูลผู้ใช้ที่ต้องการเพิ่มใหม่จำนวน:{" "}
        {!_.isNil(uploadData) &&
          uploadData.filter((row) => row.importType === "NEW").length}{" "}
        ผู้ใช้
      </Alert>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={(e) => {
            const dataFroGQL = uploadData?.map((row) => {
              delete row.account.__typename;
              return Object.assign(row.account, {
                importType: row.importType,
              });
            });
            uploadUser({ variables: { accounts: dataFroGQL } }).catch((e) =>
              console.log(e)
            );
          }}
          disabled={loading}
        >
          {loading ? "กำลังอัปโหลด" : "อัปโหลด"}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            window.location.reload();
          }}
        >
          ยกเลิก
        </Button>
      </Box>
      <UploadSuccessFailModal
        isDone={isDone}
        setDone={setDone}
        status={status}
        error={error as ApolloError}
      />
    </>
  );
};

export default ConfirmUploadUser;
