import { FormikProps, FormikValues } from "formik";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import { CheckboxList } from "./CheckboxList";

interface CustomCheckboxProps {
  name: string;
}

const CustomCheckbox = (
  props: CustomCheckboxProps &
    Omit<
      FormikProps<FormikValues>,
      "setFormikState" | "setValues" | "resetForm"
    >
) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() =>
              props.setFieldValue(props.name, !props.values[props.name])
            }
            checked={props.values[props.name] || false}
          />
        }
        label={CheckboxList[props.name].label}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
