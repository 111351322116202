import { gql } from "@apollo/client";

const editAccount = gql`
  mutation EditAccount(
    $accountId: ID!
    $birthDate: Date
    $firstName: String
    $lastName: String
    $groupId: ID
    $identityType: String
    $internalId: String
    $isAdmin: Boolean
    $joinedEducationYear: String
    $joinedType: String
    $personIdentity: String
  ) {
    ADEditAccount(
      accountId: $accountId
      birthDate: $birthDate
      firstName: $firstName
      lastName: $lastName
      groupId: $groupId
      identityType: $identityType
      internalId: $internalId
      isAdmin: $isAdmin
      joinedEducationYear: $joinedEducationYear
      joinedType: $joinedType
      personIdentity: $personIdentity
    ) {
      account {
        id
      }
    }
  }
`;

export default editAccount;
