import IDENTITY_TYPE from "../graphql/IdentityTypes";

import { IdentityTypeType } from "../types/interfaces";

const getIdentityType = (identityType: keyof IdentityTypeType) => {
  try {
    if (IDENTITY_TYPE[identityType] === undefined) {
      return "หมายเลขบัตรประชาชน";
    }
    return `หมายเลข${IDENTITY_TYPE[identityType]}`;
  } catch {
    return "หมายเลขบัตรประชาชน";
  }
};

export default getIdentityType;
