import { Formik, FormikProps } from "formik";
import _ from "lodash";

import { RotateLeft, Search } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

import {
  SearchActionQueueFilterType,
  SearchActionQueueRefetchType,
  SearchActionQueueValue,
} from "../../../../types/interfaces";
import CustomCard from "../../../CustomCard/CustomCard";
import CustomSelect from "../../../CustomSelect/CustomSelect";
import CustomTextField from "../../../TextFields/CustomTextField";

interface SearchActionQueueProps {
  loading: boolean;
  refetch: (arg0: SearchActionQueueRefetchType) => void;
}

const SearchActionQueue = ({ loading, refetch }: SearchActionQueueProps) => {
  const initialValues: SearchActionQueueValue = {
    alert: "",
    personIdentity: "",
    mail: "",
    actionType: "",
    createdAt: "",
    updatedAt: "",
    doneAt: "",
    actionStatus: "",
  };

  return (
    <Grid item xs={12}>
      <CustomCard title="ค้นหาการดำเนินการ">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false);
            try {
              const filter: SearchActionQueueFilterType = {};
              filter["account"] = {};
              if (!_.isEmpty(values.personIdentity)) {
                filter["account"]["personIdentity"] = values.personIdentity;
              }
              if (!_.isEmpty(values.mail)) {
                filter["account"]["mail"] = values.mail;
              }
              if (!_.isEmpty(values.actionType)) {
                filter["actionType"] = values.actionType;
              }
              if (!_.isEmpty(values.createdAt)) {
                filter["createdAt"] = new Date(values.createdAt)
                  .toISOString()
                  .slice(0, 10);
              }
              if (!_.isEmpty(values.updatedAt)) {
                filter["updatedAt"] = new Date(values.updatedAt)
                  .toISOString()
                  .slice(0, 10);
              }
              if (!_.isEmpty(values.doneAt)) {
                filter["doneAt"] = new Date(values.doneAt)
                  .toISOString()
                  .slice(0, 10);
              }
              if (!_.isEmpty(values.actionStatus)) {
                filter["status"] = values.actionStatus;
              }
              await refetch({ filter, page: 1 });
            } catch (error) {}
          }}
        >
          {(formikProps: FormikProps<SearchActionQueueValue>) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="personIdentity"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="mail"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="actionType"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="createdAt"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="updatedAt"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomTextField
                    name="doneAt"
                    variant="outlined"
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomSelect
                    name="actionStatus"
                    variant="outlined"
                    needNotSelected={true}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "end" }}>
                    <LoadingButton
                      color="primary"
                      type="submit"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      startIcon={<Search />}
                      loading={loading}
                    >
                      ค้นหา
                    </LoadingButton>
                    <Button
                      type="reset"
                      variant="text"
                      startIcon={<RotateLeft />}
                      onClick={() => {
                        formikProps.handleReset();
                        refetch({ filter: {}, page: 1 });
                      }}
                    >
                      รีเซต
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </CustomCard>
    </Grid>
  );
};

export default SearchActionQueue;
