import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { useRoutes } from "react-router";

import { CssBaseline, ThemeProvider } from "@mui/material";

import PageLoading from "./components/PageLoading";
import routes from "./routes/Routes";
import theme from "./theme/theme";
import { ContextProvider } from "./dataStore/context";

function App() {
  const routing = useRoutes(routes);

  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          hideIconVariant={false}
          dense={false}
        >
          <Suspense fallback={<PageLoading />}>{routing}</Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </ContextProvider>
  );
}

export default App;
