import { useSnackbar } from "notistack";
import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Clear } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import rejectPassword from "../../../../../graphql/mutation/ADRejectPassword.mutation";
import translateErrors from "../../../../../graphql/utils/translateErrors";
import {
  PendingPasswordRequestType,
  SearchActionQueueRefetchType,
} from "../../../../../types/interfaces";
import { combineName } from "../../../../../utils/table";
import CustomDialog from "../../../../CustomDialog.tsx/CustomDialog";

interface RejectPasswordDialogProps {
  data: PendingPasswordRequestType;
  refetch: (arg0: SearchActionQueueRefetchType) => void;
}

const RejectPasswordDialog = ({ data, refetch }: RejectPasswordDialogProps) => {
  const [open, setOpen] = useState(false);
  const [reject, { loading }] = useMutation(rejectPassword);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancel = async () => {
    try {
      await reject({ variables: { id: data.id } });
      handleClose();
      enqueueSnackbar("ปฏิเสธคำขอสำเร็จ", {
        variant: "success",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
      refetch({ filter: {} });
    } catch (e: any) {
      handleClose();
      enqueueSnackbar(translateErrors(e.graphQLErrors), {
        variant: "error",
        transitionDuration: { appear: 500, enter: 425, exit: 195 },
      });
    }
  };

  return (
    <>
      <Button onClick={handleOpen} startIcon={<Clear />} color="error">
        ปฏิเสธ
      </Button>
      <CustomDialog
        title="คุณต้องการปฏิเสธคำขอเปลี่ยนรหัสผ่านหรือไม่"
        open={open}
        handleClose={handleClose}
        maxWidth={480}
      >
        <DialogContent sx={{ pt: 0 }}>
          <DialogContentText>
            คุณต้องการปฏิเสธคำขอเปลี่ยนรหัสผ่านของ {combineName(data.account)}{" "}
            หรือไม่
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
          <LoadingButton
            onClick={handleCancel}
            startIcon={<Clear />}
            loading={loading}
            color="error"
          >
            ปฏิเสธ
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default RejectPasswordDialog;
