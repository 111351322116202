import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';

import ActionQueueTable from '../../../components/Base/AdminLayout/ActionQueue/ActionQueueTable';
import SearchActionQueue from '../../../components/Base/AdminLayout/ActionQueue/SearchActionQueue';
import PageError from '../../../components/PageError';
import Title from '../../../components/Title';
import FindAction from '../../../graphql/query/ADFindAction.query';
import { ADFindActionQueueQeury } from '../../../types/interfaces';

const ActionQueue = () => {
  const { loading, data, refetch } = useQuery<ADFindActionQueueQeury>(
    FindAction,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (!loading && !data) return <PageError />;

  return (
    <>
      <Title title="คิวการดำเนินการ" />
      <Grid container spacing={3}>
        <SearchActionQueue loading={loading} refetch={refetch} />
        <Grid item xs={12}>
          <ActionQueueTable
            isLoading={loading}
            actionQueues={data?.ADFindActionQueue.actionQueues}
            refetch={refetch}
            totalItems={data?.ADFindActionQueue.totalItems}
            rowsPerPage={20}
            pageRoute="/admin/actionqueue"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ActionQueue;
