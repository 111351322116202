import * as Yup from "yup";
import getIdentityType from "../utils/getIdentityType";
import _ from "lodash";

const zxcvbn = require("zxcvbn");

export const VALIDATION_LIST = {
  username: Yup.string().max(255).required("โปรดกรอกชื่อผู้ใช้"),
  password: Yup.string().max(255).required("โปรดกรอกรหัสผ่าน"),
  oldPassword: Yup.string().max(255).required("โปรดกรอกรหัสผ่านเก่า"),
  newPassword: Yup.string()
    .max(255)
    .required("โปรดกรอกรหัสผ่านใหม่")
    .matches(
      /^[~`!@#$%^&*_+?a-zA-Z0-9-]{8,16}$/,
      "รหัสผ่านต้องประกอบไปด้วยตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข เครื่องหมายพิเศษ และต้องมีความยาวกว่า 8 ตัวอักษรเท่านั้น"
    )
    .test(
      "",
      "รหัสผ่านใหม่จำเป็นที่จะต้องยากกว่านี้ รหัสผ่านที่แนะนำต้องประกอบด้วยตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข และเครื่องหมายพิเศษ",
      (value) => zxcvbn(value === undefined ? "" : value).score >= 2
    ),
  desiredPrefix: Yup.string()
    .max(255)
    .required("โปรดกรอกชื่ออีเมล")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9.]{0,13}[A-Za-z0-9]$/,
      "ชื่ออีเมลไม่ตรงกับข้อกำหนด"
    ),
  confirmPassword: Yup.string()
    .max(255)
    .required("โปรดกรอกยืนยันรหัสผ่าน")
    .oneOf([Yup.ref("newPassword"), null], "รหัสผ่านไม่ตรงกัน"),
  internalId: Yup.string().max(255).required("โปรดกรอกรหัสนักเรียน"),
  personIdentity: Yup.string().when(
    ["identityType"],
    (identityType, schema) => {
      return identityType === "T"
        ? schema
            .test(
              "len",
              "รหัสบัตรประชาชนต้องมีความยาว 13 หลัก",
              (val: string) => (_.isNil(val) ? true : val.length === 13)
            )
            .required(`โปรดกรอกรหัสบัตรประชาชน`)
        : schema
            .min(
              4,
              `${getIdentityType(identityType)}ต้องมีความยาวอย่างน้อย 4 หลัก`
            )
            .required(`โปรดกรอก${getIdentityType(identityType)}`);
    }
  ),
  birthDate: Yup.string().max(255).required("โปรดกรอกวัน เดือน ปีเกิด"),
  firstName: Yup.string().max(255).required("โปรดกรอกชื่อจริง"),
  lastName: Yup.string().max(255).required("โปรดกรอกนามสกุล"),
  identityType: Yup.string().max(255).required("โปรดเลือกประเภทผู้ใช้งาน"),
  joinedType: Yup.string().max(255).required("โปรดเลือกระดับผู้ใช้"),
  joinedEducationYear: Yup.string().max(255).required("โปรดใส่ปีที่เข้าศึกษา"),
  groupId: Yup.string().max(255).required("โปรดเลือกกลุ่มผู้ใช้"),
};
